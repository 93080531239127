import React from "react";
// import { Carousel } from "react-bootstrap";
import AcaCarouselOne from "./AcaCarouselOne";
// import AcaCarouselTwo from "./AcaCarouselTwo";

function AcademyHero(props) {
  return (
    <div>
      {/* <Carousel fade>
        <Carousel.Item>
          <AcaCarouselOne 
            Carouseltext={props.carotextone}
            Carouselsub={props.carosubone}
          />
        </Carousel.Item>
        <Carousel.Item>
          <AcaCarouselTwo 
            Carouseltext={props.carotexttwo}
            Carouselsub={props.carosubtwo}
          />
        </Carousel.Item>
      </Carousel> */}
      <AcaCarouselOne 
            Carouseltext={props.carotextone}
            Carouselsub={props.carosubone}
            
      />
    </div>
  );
}

export default AcademyHero;
