import React from 'react'
import styled from 'styled-components';
import par1 from "../../../Assets/Company/one.svg"
import par2 from "../../../Assets/Company/two.svg"
import par3 from "../../../Assets/Company/three.svg"
import par4 from "../../../Assets/Company/download.png"
import par5 from "../../../Assets/Company/five.svg"
import par6 from "../../../Assets/Company/six.svg"
import par7 from "../../../Assets/Company/seven.svg"
import par8 from "../../../Assets/Company/eight.svg"

function PartnerCom() {
    const Contain = styled.div`
    background-color: #F0F0F0;
    padding: 60px; 
    `
    const Head = styled.p`
    color: #000;
    font-size: 25px;
    font-weight: 600;
    display: flex;
    align-item: center;
    justify-content: center;
    `
    const Com = styled.img`
    backgroud-color: #fff;
    padding: 20px;
    height: 110px;
    `
    const InnerCont = styled.div`
    background: #fff;
    height: 110px;
    margin: 15px 15px 0px 15px;
    border-radius: 15px;
    box-shadow: 3px 3px 3px 3px #0000006b;
    `
  return (
    <Contain>
    <Head>Our Partnered Companies </Head>
            <div className="row m-0 d-flex text-center"> 
            <div className="col-md-3 mb-3" >
                <InnerCont >
                    <Com src={par1} alt="partner_company1" className='img-fluid'/>
                </InnerCont>
            </div>
            <div className="col-md-3 mb-3"  >
                <InnerCont >
                    <Com src={par2} alt="partner_company1" className='img-fluid'/>
                </InnerCont>
            </div>
            <div className="col-md-3 mb-3">
                <InnerCont >
                    <Com src={par3} alt="partner_company1" className='img-fluid' />
                </InnerCont>
            </div>
            <div className="col-md-3 mb-3">
                <InnerCont >
                    <Com src={par4} alt="partner_company1" className='img-fluid' />
                </InnerCont>
            </div>
            <div className="col-md-3 mb-3">
                <InnerCont>
                    <Com src={par5} alt="partner_company1" className='img-fluid' />
                </InnerCont>
            </div>
            <div className="col-md-3 mb-3">
                <InnerCont>
                    <Com src={par6} alt="partner_company1" className='img-fluid' />
                </InnerCont>
            </div>
            <div className="col-md-3 mb-3">
                <InnerCont>
                    <Com src={par7} alt="partner_company1" className='img-fluid' />
                </InnerCont>
            </div>
            <div className="col-md-3 mb-3">
                <InnerCont>
                    <Com src={par8} alt="partner_company1" className='img-fluid' />
                </InnerCont>
            </div>
            </div>
    </Contain>
  )
}

export default PartnerCom