import React from "react";
import Choose from "./Choose";
import Hero from "./Hero";
import Number from "./Number";
import PartnerCom from "./PartnerCom";
import ProjectsMain from "./ProjectsMain";
import Success from "./Success";
import WhyUs from "./WhyUs";
import Works from "./Works";
import styled from "styled-components";

function HeroMain() {
  const Contain = styled.div`
    @media only screen and (max-width: 768px){
          margin-top: 20px;
        }
    @media only screen and (max-width: 820px){
          margin-top: 20px;
        }
  `
  return (
    <Contain>
      <Hero />
      <WhyUs />
      <Works />
      <Choose />
      <ProjectsMain /> 
      <PartnerCom />
      <Success />
      <Number /> 
    </Contain>
  );
}

export default HeroMain;
