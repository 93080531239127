import React from "react";
import styled from "styled-components";
import Pic from "../../../Assets/Landing_Page/CardBack.svg";

function WorksCard(props) {
  const ContainCard = styled.div`
    background-color: #fff;
    height: 290px;
    width: 290px;
    border-radius: 15px;
    z-index: 2;
    position: relative;
    margin-top: 10px;
    box-shadow: 3px 3px 3px 3px #0000006b;
  `;
  const ContainImage = styled.div`
    z-index: 0;
  `;
  const Image = styled.img`
    height: 100px;
    margin-left: -40px;
    margin-top: -60px;
  `;
  const CardImage = styled.img`
    height: 90px;
  `;
  const Head = styled.p`
    color: #ff7809;
    margin-top: -20px;
    font-size: 18px;
  `;
  // const Icon = styled.i`
  //   margin-right: 5px;
  //   color: #ff7809;
  // `;
  const UnList = styled.ul`
    padding: 20px;
    padding-top: 0px;
    margin-top: -10px;
  `

  return (
    <div className="mb-2">
      <ContainCard>
        <div className="text-center">
          <CardImage src={props.headIcon} alt="hiring" />
          <Head>{props.head}</Head>
        </div>
        <UnList>
          <li className="d-flex mb-1">
            <div>
            {props.dot1}
            </div>
            <div>{props.lone}</div>
          </li>
          <li className="mb-1 d-flex">
            <div>
            {props.dot2}
            </div>
            <div>{props.ltwo}</div>
          </li>
          <li className="mb-1 d-flex">
            <div>
            {props.dot3}
            </div>
            <div>{props.lthree}</div>
          </li>
        </UnList>
      </ContainCard>
      <ContainImage>
        <Image src={Pic} alt="dots" />
      </ContainImage>
    </div>
  );
}

export default WorksCard;
