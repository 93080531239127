import React,{useState} from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import pic from "../../../../Assets/Academy/Academy_hero1.jpg";
import AcaTestimonialContent from "./AcaTestimonialContent";
import Carousel from 'react-bootstrap/Carousel';

function AcaTestimonial(props) {

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const Image = styled.img`
    /* height: 450px;
    width: 900px */
  `;
  const WhyUS = styled.div`
    display: flex;
    justify-content: center;
    align-item: center;
    padding: 15px;
    margin-bottom: 20px;
  `;
  const Head = styled.p`
    color: #000;
    margin: 0 !important;
    font-size: 25px;
    font-weight: 600;
  `;

  return (
    <div>
      <WhyUS>
        <Head>Testimonial</Head>
      </WhyUS>
      <Container>
        <div className="row m-0">
       
        <div className="col-md-6">
            <Image src={pic} />
          </div>
          <div className="col-md-6">
    <Carousel activeIndex={index} onSelect={handleSelect} variant="dark">
      <Carousel.Item>
      <AcaTestimonialContent 
        testiname={props.testinameOne}
        testContent={props.testContentOne}
      />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <AcaTestimonialContent 
        testiname={props.testinameTwo}
        testContent={props.testContentTwo}
      />

        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <AcaTestimonialContent 
        testiname={props.testinameThree}
        testContent={props.testContentThree}
      />

        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
            
          </div>
        
          
        </div>
        </Container>
    </div>
  );
}

export default AcaTestimonial;
