import React from "react";
import styled from "styled-components";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

function AcaWhyBox(props) {
  const Contain = styled.div`
    background-color: #012068;
    padding: 10px;
    color: #fff;
    height: 190px
  `;
  const Circle = styled.div`
    background-color: #fff;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    color: #4eb92f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  `;
  const Head = styled.h5`
    font-weight: 600;
    color: #fff;
  `;
  const Inner = styled.p` 
    font-weight: 400;
    font-size: 12px;
  `
  return (
    <Contain>
      <div className="d-flex justify-content-center">
        <Circle>
          <QueryStatsIcon />
        </Circle>
      </div>
      <div>
        <Head className="text-center">{props.heading}</Head>
        <Inner>
          {props.subhead}
        </Inner>
      </div>
    </Contain>
  );
}

export default AcaWhyBox;
