import React from "react";
import styled from "styled-components";
import AcaWhyBox from "./AcaWhyBox";
import pic from "../../../../Assets/Academy/Academy_hero1.jpg";
import { Container } from "react-bootstrap";

function AcademyWhyUs(props) {
  const WhyUS = styled.div`
    display: flex;
    justify-content: center;
    align-item: center;
    padding: 15px;
    margin-bottom: 20px;
  `;
  const Head = styled.p`
    color: #000;
    margin: 0 !important;
    font-size: 25px;
    font-weight: 600;
  `;
  const Image = styled.img`
    height: 355px;
  `;

  return (
    <div>
      <WhyUS>
        <Head>Why Choose us</Head>
      </WhyUS>
      <Container>
        <div className="row m-0">
          <div className="col-md-6">
            <div className="row m-0">
              <div className="col-md-6 mb-2">
                <AcaWhyBox
                  heading={props.WhyBoxheadOne}
                  subhead={props.WhyBoxsubOne}
                />
              </div>
              <div className="col-md-6 mb-2">
                <AcaWhyBox
                  heading={props.WhyBoxheadTwo}
                  subhead={props.WhyBoxsubTwo}
                />
              </div>
              <div className="col-md-6 mb-2">
                <AcaWhyBox
                  heading={props.WhyBoxheadThree}
                  subhead={props.WhyBoxsubThree}
                />
              </div>
              <div className="col-md-6 mb-2">
                <AcaWhyBox
                  heading={props.WhyBoxheadFour}
                  subhead={props.WhyBoxsubFour}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <Image src={pic} alt="person" />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default AcademyWhyUs;
