import React from "react";
import { Accordion } from "react-bootstrap";

function AcaFaqAccordion(props) {
  return (
    <div>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {props.qus1}
          </Accordion.Header>
          <Accordion.Body>
            {props.ans1}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{props.qus2}</Accordion.Header>
          <Accordion.Body>
            {props.ans2}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            {props.qus3}
          </Accordion.Header>
          <Accordion.Body>
            {props.ans3}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            {props.qus4}
          </Accordion.Header>
          <Accordion.Body>
            {props.ans4}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>{props.qus5}</Accordion.Header>
          <Accordion.Body>
           {props.ans5}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            {props.qus6}
          </Accordion.Header>
          <Accordion.Body>
            {props.ans6}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>
            {props.qus7}
          </Accordion.Header>
          <Accordion.Body>
            {props.ans7}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default AcaFaqAccordion;
