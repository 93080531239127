import React from 'react';
import {useState, useRef} from 'react';
import styled  from 'styled-components';
import AcaFaqAccordion from './AcaFaqAccordion';
import {Container} from "react-bootstrap";

function AcaFaqMain(props) {
  const formRef = useRef(null)
  const scriptUrl = "https://script.google.com/macros/s/AKfycbwF2BsKiKp4DIysdWR9fAf-x_SS3EmpmpozAet2sxW7PCWwwrT6bqqXZbhzP_6OBXqR5w/exec"
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) =>{
        e.preventDefault()
        setLoading(true)

        fetch(scriptUrl, {
        method: 'POST', 
        body: new FormData(formRef.current),

    }).then(res => alert("SUCCESSFULLY SUBMITTED"), 1000
            
        )
        .catch(err => console.log(err))
    }

  const Contain = styled.div` 
    /* margin-bottom: 10px; */
    padding: 20px;
  `
  const WhyUS = styled.div`
    display: flex;
    justify-content: center;
    align-item: center;
    padding: 15px;
    margin-bottom: 20px;
  `;
  const Head = styled.p`
    color: #000;
    margin: 0 !important;
    font-size: 25px;
    font-weight: 600;
  `;
  const Contform = styled.div` 
   padding-right: 40px;
   padding-left: 40px;
  `
  return (
    <Contain>
    <WhyUS>
        <Head>FAQ</Head>
      </WhyUS>
      <Container>
      <div className="row m-0">
        <div className="col-md-6 mb-4">
          <AcaFaqAccordion 
            qus1={props.qus1}
            ans1={props.ans1}
            qus2={props.qus2}
            ans2={props.ans2}
            qus3={props.qus3}
            ans3={props.ans3}
            qus4={props.qus4}
            ans4={props.ans4}
            qus5={props.qus5}
            ans5={props.ans5}
            qus6={props.qus6}
            ans6={props.ans6}
            qus7={props.qus7}
            ans7={props.ans7}
          />
        </div>
        <div className="col-md-6 mb-4">
        <Contform>
        <h4>Register Here</h4>
        <form ref={formRef} onSubmit={handleSubmit} name='mentor-google-sheet'>
            <div class="mb-3">
              <label for="exampleInput2" class="form-label">Full Name</label>
              <input type="text" name='Full Name' class="form-control" id="exampleInput2" required/>
            </div>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Email address</label>
              <input type="email" name='Email ID' class="form-control" id="exampleInputEmail1" />
            </div>
            <div class="mb-3">
              <label for="exampleInput1" class="form-label">Contact Number</label>
              <input type="number" name='Contact Number' class="form-control" id="exampleInput1" />
            </div>
            <div class="mb-3">
              <label for="exampleInput1" class="form-label">Tell us about yourself</label>
              <textarea rows="5" name='Tell us about yourself' class="form-control" id="exampleInput1"></textarea>
            </div>
            <button type="submit" class="btn btn-primary me-1" value={loading ? "Loading..." : "SEND MESSAGE"}>Register</button>
            <button type="reset" class="btn btn-warning">Cancel</button>
          </form>
        </Contform>
        </div>
      </div>
      </Container>
      
    </Contain>
  )
}

export default AcaFaqMain