import React from 'react'
import AcademyCardhold from './../Academy/Hero/AcademyCardhold';
import WhatWeDoMain from './../Academy/WhatWeDo/WhatWeDoMain';
import AcademyWhyUs from './../Academy/WhyChoose/AcademyWhyUs';
import AcaTestimonial from './../Academy/Testimonial/AcaTestimonial';
import AcaFaqMain from './../Academy/FAQ/AcaFaqMain';
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import SchoolIcon from "@mui/icons-material/School";
import one from "../../../Assets/Academy/tab1.jpg";
import two from "../../../Assets/Academy/Mnt/mnt2.jpg";
import three from "../../../Assets/Academy/Mnt/mnt3.jpg";
import four from "../../../Assets/Academy/Mnt/mnt4.jpg";
import MntHeader from './MntHeader';


function MentorMain() {
  return (
    <div>
      {/* <AcademyHero
        carotextone="Take your business to the next Level"
        carosubone="We are your partner in your success "
        carotexttwo="We will be your partner in building your business."
        carosubtwo="We are your partner in your success "
      /> */}
      <MntHeader 
        Carouseltext=" We will be your Partner in Building your Business together"
        // Carouselsub="We are your partner in your success "
      />
      <AcademyCardhold
        cardIconone={<QueryStatsIcon />}
        cardHeadone="Industry Level Projects"
        cardSubone="Work on emerging technology and business areas "

        cardIcontwo={<TipsAndUpdatesIcon />}
        cardHeadtwo="Mentorship Support"
        cardSubtwo="Work with the company SPOC and grow your self "

        cardIconthree={<SchoolIcon />}
        cardHeadthree="Many Earning Opportunities "
        cardSubthree="Get paid on time for each project completed by you"
      />
      <WhatWeDoMain 
        tabOne="Complete the Registration Process"
        tabTwo="Get Started"
        tabThree="Define and Finalize Scope of Work"
        tabFour="Deliver the Work and Get paid"
        picOne={one}
        picTwo={two}
        picThree={three}
        picFour={four}
        tabheadone="Complete the Registration Process"
        tabonepoint1="Update your details such as first name, last name, email id "
        tabonepoint2="Select the Domains in which you want to mentor"
        tabonepoint3="Upload your resume and someone from our team shall contact you"

        tabheadtwo="Get Started"
        // tabsubtwo="We are your partner in your growth journey. Launch your website in under 2 hours "
        tabtwopoint1="Once shortlisted , select the project on which you want to work"
        tabtwopoint2="Share a sample of your work"
        tabtwopoint3="Once you are selected for the project, a SPOC will be assigned to you"

        tabheadthree="Define and Finalize Scope of Work"
        tabthreepoint1="Build the scope of the Project with the Academia Experts "
        tabthreepoint2="Freeze the time, budget and mode of teaching : Pre-recorded content vs Live sessions or both"
        tabthreepoint3="Sign the MoU and get started "

        tabheadfour="Deliver the Work and Get paid"
        // tabsubfour="Select the options that your business needs with our platform and pay for what you need "
        tabfourpoint1="Deliver the Project as per the timelines "
        tabfourpoint2="Update the SPOC "
        tabfourpoint3="Get Paid for the work completed "
      />
      <AcademyWhyUs 
        WhyBoxheadOne = "Choose your medium of Teaching"
        WhyBoxsubOne="You may decide if you want to opt for pre-recorded content or Live Sessions "

        WhyBoxheadTwo="Your Growth Partner"
        WhyBoxsubTwo="Identify your strength areas and become stronger by working on relevant projects with us"

        WhyBoxheadThree="Facilitate Students"
        WhyBoxsubThree="Work with students across India and help learners  gain from your knowledge and experience "

        WhyBoxheadFour="Round the clock Support"
        WhyBoxsubFour="Quick response time to all your queries and concerns "
      />
      {/* <AccTeamMain /> */}
      <AcaTestimonial
        testinameOne="Anup Sharma" 
        testContentOne="Working with Movidu gives us alot of job satisfaction compared to anything else. There is personal touch with students and team is very supportive in all aspects. It is my job to grow here by imparting knowledge and there is pleasure from imparting knowledge."
        testinameTwo="Jacob"
        testContentTwo="I’m glad that I decided to work with Movidu. Being in the organization, I feel myself growing, both personally and professionally. The people working here are supportive and the students are curious. What I love the most is the freedom to create a way of working that works best for me."
        testinameThree="Dennis"
        testContentThree="Movidu has a amazing host of operations team, that makes getting onboard with them quick and amazing, the team is very very friendly for the mentors and they give constant support during and after the classes are done. The students they have are amazing and very eager to learn new things which causes the mentors morale to go up."
      />
      <AcaFaqMain
      qus1="What are the qualifications required to become a mentor?"
            ans1="To become a mentor, you need to have relevant experience and knowledge in the field you wish to mentor in. A degree or certification in the related field is an added advantage."
            qus2="How do I apply to become a mentor?"
            ans2="You can apply to become a mentor by filling out the application form available on our website. Our team will review your application and get back to you if you meet the required criteria."
            qus3="Do I have to pay any fees in order to become a Mentor?"
            ans3="No, there is no registration fees to be paid by the Mentor."
            qus4="What are the requirements to be a successful mentor on Movidu platform?"
            ans4="Timely delivery of the Sessions, projects , content on time. Along with that we expect that the content should be original and give source credit incase if using someone else’s content. The content created for our brand cannot be offered to any other customer of yours. In addition you need to maintain a rating of 6 and above at all times. "
            qus5="How are mentors assigned to students?"
            ans5="Mentors are assigned to students based on their area of expertise and the requirements of the project."
            qus6="What happens if I am unable to complete a project or mentorship session?"
            ans6="If you are unable to complete a project or mentorship session, please inform us as soon as possible so that we can make alternate arrangements. In case of a missed live session, it may be rescheduled at a mutually convenient time."
            qus7="What kind of projects will I be mentoring?"
            ans7="We offer mentorship opportunities in various fields including technology, design, marketing, and business. The specific projects you will be mentoring will depend on your qualifications and expertise."
       />
    </div>
  );
}

export default MentorMain