import React from 'react'

function JobCardDetails(prop) {
  return (
    <div className="card-details">
                    <h4>{prop.compname}</h4>
                    <h5>{prop.jobname}</h5>
                    <div className="project-desc">
                        <p>
                            {prop.compdesc}
                        </p>
                        <p><span>Location: </span> {prop.loc}</p>
                        <p><span>Job Type: </span>{prop.worktype}</p>
                        <p><span>Duration: </span>{prop.duration}</p>
                        <p><span>Salary/Stipend: </span>{prop.stipend}</p>
                        <p><span>Vaccancy: </span>{prop.novaccancy}</p>
                        <p><span>Job Description: </span>{prop.jobdesc}</p>
                        <p><span>Eligiblity Criteria: </span>{prop.eligiblity}</p>
                        <p><span>Skills Required: </span>{prop.skills}</p>
                        <p><span>Roles & Responsiblities: </span>
                            {prop.roles}
                        </p>
                        <a data-bs-toggle="modal" data-bs-target="#applyjobModal" className="btn btn-warning">Apply Now</a>
                    </div>
                </div>
  )
}

export default JobCardDetails