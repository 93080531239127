import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// import VerifiedIcon from '@mui/icons-material/Verified';
// import { Button } from 'react-bootstrap'
// import GoogleIcon from '@mui/icons-material/Google';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import React, { useState, useEffect } from 'react';
import API from '../../Routes/api';
import Swal from 'sweetalert2';

const card = {
  padding: 50

}

function SignupData() {
  const [validated, setValidated] = useState(false);
  const [referrer, setReferrer] = useState([]);
  const [user, setUser] = useState([]);
  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.searchParams.has('q')) {
      getLinkFromQuery(url.searchParams.get('q'));
    }
  }, []);
  const getLinkFromQuery = (query) => {
    API.post('company/link/fetch/whatsapp', { q: query }).then(res => {
      if (res.data.code === 200) {
        console.log(res.data);
        setReferrer(res.data.data);
        setUser(
          prevState => ({
              ...prevState,
              ['referred_by']: res.data.data.referrerId,['referrer_type']:res.data.data.referrerType
          })
      );
      }
    }).catch(err => {
      if (err.code === 'ERR_BAD_REQUEST') {
        Swal.fire({
          title: err.response.data.status,
          text: err.response.data.message,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }
    });
  }

  const handleChange = (e) => {
      console.log(e)
      console.log(user)
      setUser(
          prevState => ({
              ...prevState,
              [e.target.name]: e.target.value
          })
      );
  
  }

 
  const handleSubmit = (event) => {    
    console.log(user);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    event.preventDefault();
    submitApplication();
  };
  const submitApplication = () =>{
    if(validated === true){
      API.post('/api/v1/signup',user).then(res=>{
        console.log(res)
          if(res.data.code===200){
              
              window.location.href = res.data.data.redirect_url;
          }      
      }).catch(err=>{
          if(err.code==='ERR_BAD_REQUEST'){
          Swal.fire({
              title: err.response.data.status,
              text: err.response.data.message,
              icon: 'error',
              confirmButtonText: 'OK'
          })
          } 
      });
    }
  }
  return (
    <Card style={card} className="text">
      <Card.Body >
        <h3><b>Create a new account</b></h3>
        <Card.Text>
          <p style={{ fontSize: '13px', }}>Use your email to create a new account</p>
          <p style={{ fontSize: '13px', }}> Already have an account?{" "}
            <a href={'http://localhost:3001/login'} ><b>Login</b></a> </p>

        </Card.Text>
        {/* <Button variant="danger"> {GoogleIcon && <GoogleIcon type={GoogleIcon}/>} Login with Google</Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary"> {LinkedInIcon  && <LinkedInIcon  type={LinkedInIcon }/>} Login with LinkedIn </Button> */}
        <hr style={{ marginBottom: '40px' }}></hr>
        {/* <Card.Title>Thank you</Card.Title>
        <Card.Text>
        You Are Just One Click Away
        </Card.Text> */}

        {/* <Button variant="primary">Submit</Button> */}

        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Label>You were Referred by {referrer.referrerName} from {referrer.referrerCompany}</Form.Label>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>First name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="First name"
                name="first_name"
                value={user.first_name}
                onChange={handleChange}
              // defaultValue="Mark"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom02">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Last name"
                name="last_name"
                value={user.last_name}
                onChange={handleChange}
              // defaultValue="Otto"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom03">
              <Form.Label>Email-Id</Form.Label>
              <Form.Control type="text" placeholder="Email-Id" required name="email" value={user.email}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid Email.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom03">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" required name="password" value={user.password}
                onChange={handleChange}/>
              <Form.Control.Feedback type="invalid">
                Please provide Password.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group className="mb-3">
            <Form.Check required label="Agree to terms and conditions" feedback="You must agree before submitting." feedbackType="invalid" />
          </Form.Group>
          <Button type="submit" onClick={handleSubmit}>Submit form</Button>
        </Form>

      </Card.Body>
      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
    </Card>
  );
}

export default SignupData;