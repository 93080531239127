import React from 'react'
import AcademyCardhold from './../Academy/Hero/AcademyCardhold';
import WhatWeDoMain from './../Academy/WhatWeDo/WhatWeDoMain';
import AcademyWhyUs from './../Academy/WhyChoose/AcademyWhyUs';
import AccTeamMain from './../Academy/TeamMember/AccTeamMain';
import AcaFaqMain from './../Academy/FAQ/AcaFaqMain';
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import SchoolIcon from "@mui/icons-material/School";
import one from "../../../Assets/Academy/tab1.jpg";
import two from "../../../Assets/Academy/Clg/clg1.jpg";
import three from "../../../Assets/Academy/Clg/clg2.jpg";
import four from "../../../Assets/Academy/Clg/clg3.jpg";
import ClgHeader from './ClgHeader';


function ClgMain() {
  return (
    <div>
      {/* <AcademyHero
        carotextone="Take your business to the next Level"
        carosubone="We are your partner in your success "
        carotexttwo="We will be your partner in building your business."
        carosubtwo="We are your partner in your success "
      /> */}
      <ClgHeader 
        Carouseltext="Accelerate Job-relevant Skill Development sought by Employers"
        // Carouselsub="We are your partner in your success "
      />
      <AcademyCardhold
        cardIconone={<QueryStatsIcon />}
        cardHeadone="Industry Level Projects"
        cardSubone="Expose students to latest Industry requirements"

        cardIcontwo={<TipsAndUpdatesIcon />}
        cardHeadtwo="Mentorship Support"
        cardSubtwo="Learn from World Class Industry Experts "

        cardIconthree={<SchoolIcon />}
        cardHeadthree="Internships and Hiring Support"
        cardSubthree="Exclusive Internships and Hiring Opportunities available "
      />
      <WhatWeDoMain 
        
        tabOne="Complete the Registration Process"
        tabTwo="Select and Launch "
        tabThree="Grow and Stand Out"
        tabFour="Internships and Placements"
        picOne={one}
        picTwo={two}
        picThree={three}
        picFour={four}
        tabheadone="Complete the Registration Process"
        tabonepoint1="Update the details as mentioned in the registration form"
        tabonepoint2="Please check the suite of services we are offering "
        tabonepoint3="Please update the requirements for the services needed "

        tabheadtwo="Select and Launch"
        tabsubtwo="Select the Product and services as needed "
        tabtwopoint1="Select the projects for your students from the list"
        tabtwopoint2="Select the required Course Certifications and Industry Mentors"
        tabtwopoint3="Empower your student learners with right Industry Level Projects and valuable Professional Certificate."

        tabheadthree="Grow and Stand Out"
        tabsubthree="Select Domains with which you need Project Support"
        tabthreepoint1="Attract students by developing the skills sought by employers"
        tabthreepoint2="Help your graduates stand out by giving them hands-on learning with Guided Projects to help students master real-world skills."
        tabthreepoint3="Help students explore the right career path while learning across web and mobile."

        tabheadfour="Internships and Placements"
        tabsubfour="We offer support with Live Projects, Internships and Placements"
        tabfourpoint1="Attract companies by developing the skills sought by employers in the students "
        tabfourpoint2="We Deliver cutting-edge learning and Empower your student learners build job confidence "
        tabfourpoint3="Support career exploration with our Mentors and Industry experts"
      /> 
      <AcademyWhyUs 
        WhyBoxheadOne = "1 Stop Solution "
        WhyBoxsubOne="We are your one stop solution from offering Live Projects to Internships to Placement support"

        WhyBoxheadTwo="Your Growth Partner   "
        WhyBoxsubTwo="Identify your growth products and services and deploy it within 24 hours with our solutions "

        WhyBoxheadThree="Cost Effective and Secure"
        WhyBoxsubThree="Help improve student employability with our solutions "

        WhyBoxheadFour="Round the clock Support"
        WhyBoxsubFour="Quick response time to all your queries and concerns "
      />
      <AccTeamMain />
      {/* <AcaTestimonial
        testinameOne="Michael Harper" 
        testinameTwo="Amelia Daniel"
        testinameThree="Alex Mason"
      /> */}
      <AcaFaqMain 
        qus1="How can we register on InMovidu Platform"
        ans1="You just need to click on the link Create Account and update your details and complete the registration process and create your account"
        qus2="What all products can I see on the platform "
        ans2="We offer all the below mentioned Technology Product Services such as LMS , Student Dashboard, Mentor or Teacher Dashboard and many other services."
        qus3="What kind of academic support does Movidu provide?"
        ans3="We offer Certification Programs, Live Industry Projects, Internship and Placements opportunities to the students."
        qus4="What are the benefits of collaborating with Inmovidu"
        ans4="Augment your curriculum based on what is happening in the industry, Help improve student employability with Live Industry Projects"
        qus5="Where are the mentors from?"
        ans5="The Mentors are Industry Experts with a minimum of 2 years experience in the domain"
        qus6="What will be the medium of learning"
        ans6="We offer a combination of Self Paced Learning and Instructor Led Trainings. The mentorship is offered by the Industry Experts as well as the team from the partnered companies whose projects the students shall be working on."
        // qus7="What other support can I expect from the company?"
        // ans7="We offer various kinds of services to your Organisation such as LMS
        //     , Student Dashboard, Mentor Dashboard, Mentors, Projects, Payment
        //     Gateway to collect fees online from the students"
        
      />
    </div>
  );
}

export default ClgMain