import React from 'react'
import  styled  from 'styled-components';
import "../../../../App.css"

function AcaCarouselOne(props) {
    const Text = styled.h1`
    color: #fff;
    padding-top: 260px;
    padding-left: 150px;
    margin-bottom: 40px;

    @media only screen and (max-width: 768px ){
      padding: 50px;
      padding-bottom: 0px;
      padding-top: 150px;
    }
    @media only screen and (max-width: 820px ){
      padding: 50px;
      padding-bottom: 0px;
      padding-top: 150px;
    }
  `
    const Sub = styled.h5` 
    padding-left: 150px;
    color: #fff;

    @media only screen and (max-width: 768px ){
         padding-left: 50px;
         padding-bottom: 50px;

    }
    @media only screen and (max-width: 820px ){
      padding-left: 50px;
      padding-bottom: 50px;
    }
    `
    const KMore = styled.a`
    background-image: linear-gradient(to right, #fea902, #ff7609);
    margin: 10px 150px;
    border-radius: 25px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px;
    text-decoration: none;

    @media only screen and (max-width: 768px) {
          margin: 10px;
          margin-bottom: 90px;

    }
    @media only screen and (max-width: 820px) {
      margin: 10px;
      margin-bottom: 90px;
    }
  `;

  return (
    <div >
      <div className="academyhero1 row m-0">
        <div className="col-md-12 acaColor">
          <Text>
          {props.Carouseltext}
          </Text>
          <Sub>
          {props.Carouselsub}
          </Sub>
          <KMore href="#wwd2">Know More</KMore>
        </div>
      </div>
    </div>
  )
}

export default AcaCarouselOne