import React from "react";
import styled from "styled-components";
import AccTeamCard from "./AccTeamCard";

function AccTeamMain() {
  const WhyUS = styled.div`
    display: flex;
    justify-content: center;
    align-item: center;
    padding: 15px;
    margin-bottom: 20px;
  `;
  const Head = styled.p`
    color: #000;
    margin: 0 !important;
    font-size: 25px;
    font-weight: 600;
  `;
  return (
    <div>
      <WhyUS>
        <Head>Team Member</Head>
      </WhyUS>
      <div className="row m-0">
        <div className="col-md-4 d-flex justify-content-center">
          <AccTeamCard />
        </div>
        <div className="col-md-4 d-flex justify-content-center">
          <AccTeamCard />
        </div>
        <div className="col-md-4 d-flex justify-content-center">
          <AccTeamCard />
        </div>
      </div>
    </div>
  );
}

export default AccTeamMain;
