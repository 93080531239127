import React from "react";
import styled from "styled-components";
import pic1 from "../../Assets/About_Page/middle_two.svg";

function Team() {
  const Image = styled.img`
    height: 500px;

    @media only screen and (max-width: 768px) {
      height: 360px;
    }
  `;
  const ColOne = styled.div``;
  const Contain = styled.div`
    padding: 60px;
    @media only screen and (max-width: 768px) {
      padding: 35px;
    }
  `;
  const InnerCard = styled.div`
    background-color: #fae8ec;
    color: #000;
    padding: 20px;
    border-radius: 25px;
  `;
  const Text = styled.p`
    /* margin-top: 140px; */

    @media only screen and (max-width: 768px) {
      margin-top: 25px;
    }
  `;
  const Head = styled.div``;
  return (
    <div>
      <Contain>
        <div className="row m-0">
          <div className="col-md-6 mb-3 d-flex justify-content-center">
            <InnerCard className="">
              <Head className="d-flex text-center justify-content-center">
                <h4> Our Founders</h4>
              </Head>
              <Text>
                <li>
                <b>
                Mridula Chhetri Haas School of Business
                </b>
                </li>
                <br />
                <li>
                Co-founder comes with a decade + of experience in working with startups and multinational companies from all around the world. She's a results-driven professional with a global exposure, international and entrepreneurial experience in business strategy, product development, strategic marketing in shared economy sector. Now she's building India's largest student workforce!

                </li>
                <br />
                <li>
                <b>

                Pushpraj Singh Rajput 
                </b>
                </li>
                <br />
                <li>
                Co-founder Rich experience of leading business end to end, running a P&L, building strategic business partnerships, driving market development, driving digital transformation. Entrepreneurial mindset in solving business problems, identifying new opportunities and building business with exponential growth trajectory, finding the right talent, and building teams with a high-performance culture

                </li>
              </Text>
            </InnerCard>
          </div>
          <ColOne className="col-md-6 mb-3 d-flex justify-content-start">
            <Image src={pic1} alt="our_vision" />
          </ColOne>
        </div>
      </Contain>
    </div>
  );
}

export default Team;
