import { BrowserRouter, Routes, Route } from "react-router-dom";
import HeroMain from "./Components/LandingPage/LandingPage/HeroMain";
import "./App.css"
import Navbar from './Components/Navbar/NavBar';
import { FooterContainer } from './Components/LandingPage/LandingPage/footer/footer';
import AboutMain from './Components/AboutPage/AboutMain';
import CompanyMain from './Components/CompanyPage/CompanyMain';
import Jobs from './Components/CareerPage/Jobs';
import BlogMain from './Components/BlogsPage/BlogMain';
import BlogInfoMain from './Components/BlogsPage/Info/BlogInfoMain';
import LearningMain from "./Components/Learning Partners/LearningMain";
import ClgMain from './Components/Learning Partners/College/ClgMain';
import MentorMain from './Components/Learning Partners/Mentor/MentorMain';
import ProjectPage from "./Components/ProjectPage/ProjectPage";
import LoginMain from "./Components/Login/LoginMain";
import SignupMain from "./Components/Signup/SignupMain";

function App() {
  return (
    <div className="App">
           <BrowserRouter>
        <Navbar />
        <Routes>
        <Route path="/" element={<HeroMain />}> </Route>
        <Route path="/about" element={<AboutMain />} ></Route>
        <Route path="/company" element={<CompanyMain />}></Route> 
        <Route path="/career" element={<Jobs/>}></Route>
        <Route path="/blog" element={<BlogMain />}></Route> 
        <Route path="/bloginfo" element={<BlogInfoMain />} ></Route>
        <Route path="/learningpartner" element={<LearningMain />} ></Route>
        <Route path="/clg" element={<ClgMain />} ></Route>
        <Route path="/mentor" element={<MentorMain />} ></Route>
        <Route path="/projectMain" element={<ProjectPage />} ></Route>
        <Route path="/login" element={<LoginMain />} ></Route>
        <Route path="/signup" element={<SignupMain />} ></Route>



        </Routes>
        <FooterContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
