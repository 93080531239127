import React from 'react'
import styled  from 'styled-components';

function AcademyCards(props) {
    const Contain = styled.div`
    background-color: #012068;
    padding: 20px;
    border-radius: 1px 50px;
    color: #fff;
    margin-top: -100px;
    height: 220px;

    @media only screen and (max-width: 820px ){
      /* margin-top: 130px; */
      margin-bottom: 110px;
      height: auto;
    }
    @media only screen and (max-width: 921px ){
      padding: 50px;
      height: auto;
    }
    `
    const Circle = styled.div`
    background-color: #fff;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    color: #4EB92F;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    `
    const Inner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    `
    const Head = styled.h5`
    font-weight: 600;
    `
  return (
    <Contain className='d-flex justify-content-center'>
    <Inner>
    <Circle>
    {props.icon}
    </Circle>
<div>
<Head className='text-center'>{props.heading}</Head>
<h6>{props.sub}</h6>
</div>
    </Inner>
    
       
    </Contain>
  )
}

export default AcademyCards