import React from "react";
import styled from "styled-components";
import company from "../../../Assets/Icons/1.svg";
import college from "../../../Assets/Icons/2.svg";
import student from "../../../Assets/Icons/3.svg";
import projects from "../../../Assets/Icons/4.svg";

function Number() {
  const Contain = styled.div`
    background: #eaeaea;
    padding: 60px;
  `;
  const Words = styled.p`
    font-size: 20px;
    font-weight: 600;
  `;
  return (
    <Contain>
      <div className="row m-0">
        <div className="col-12 col-sm-12 col-md-6 col-lg-3 p-5">
          <div className="d-flex">
            <img src={company} alt="company" style={{ marginRight: 15, height: 90 }}/>
            <Words>100+ partnered companies</Words>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-3 p-5">
          <div className="d-flex">
            <img src={college} alt="company" style={{ marginRight: 15, height: 90 }} />
            <Words>4000+ Colleges</Words>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-3 p-5">
          <div className="d-flex">
            <img src={student} alt="company" style={{ marginRight: 15, height: 90 }} />
            <Words>1 Million+ Students</Words>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-3 p-5">
          <div className="d-flex">
            <img src={projects} alt="company" style={{ marginRight: 15, height: 90 }} />
            <Words>1000+ Projects</Words>
          </div>
        </div>
      </div>
    </Contain>
  );
}

export default Number;
