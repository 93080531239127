import React from "react";
import styled from "styled-components";
import pic1 from "../../Assets/About_Page/middle_one.svg";

function OurCore() {
  const Image = styled.img`
    height: 500px;

    @media only screen and (max-width: 768px) {
      height: 360px;
    }
  `;
  const ColOne = styled.div``;
  const Contain = styled.div`
    padding: 60px;

    @media only screen and (max-width: 768px) {
      padding: 35px;
    }
  `;
  const InnerCard = styled.div`
    background-color: #fae8ec;
    color: #000;
    padding: 20px;
    border-radius: 25px;
  `;
  const Text = styled.p`
    /* margin-top: 140px; */

    @media only screen and (max-width: 768px) {
      margin-top: 25px;
    }
  `;
  const Head = styled.div``;
  return (
    <Contain>
      <div className="row m-0">
        <ColOne className="col-md-6 mb-3 d-flex justify-content-end">
          <Image src={pic1} alt="our_vision" />
        </ColOne>
        <div className="col-md-6 mb-3 d-flex justify-content-center">
          <InnerCard className="">
            <Head className="d-flex text-center justify-content-center">
              <h4> Our Core Values</h4>
            </Head>
            <Text>
              <li>
              At Movidu, we believe that our values are what make us who we are. They define who we are as individuals and how we interact with the world.
              </li>
              <br />
              <li>
              <b>Integrity</b> - We value honesty, transparency and trust in our relationships.
              </li>
              <br />
              <li>
              <b>
              Collaborative approach
              </b>
               - We believe that together, we can do more than we can alone.
              </li>
              <br />
              <li>
              <b>
              Owning your work
              </b>
               - We believe you should take pride in your work and respect the efforts of others.
              </li>
              <br />
              <li>
              <b>
              Not afraid to fail
              </b>
               - We believe that failure is a natural part of growth, so we're not afraid to try new things out and learn from them.
              </li>
              <br />
              <li>
              <b>
              Desire to win
              </b>
               - We want to be the best at what we do, and that means always striving for excellence.
              </li>
            </Text>
          </InnerCard>
        </div>
      </div>
    </Contain>
  );
}

export default OurCore;
