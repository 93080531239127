import React from "react";
import styled from "styled-components";
import pic1 from "../../Assets/About_Page/middle_two.svg";

function Mission() {
  const Image = styled.img`
    height: 500px;

    @media only screen and (max-width: 768px) {
      height: 360px;
    }
  `;
  const ColOne = styled.div``;
  const Contain = styled.div`
    padding: 60px;
    @media only screen and (max-width: 768px) {
      padding: 35px;
    }
  `;
  const InnerCard = styled.div`
    background-color: #fae8ec;
    color: #000;
    padding: 20px;
    border-radius: 25px;
  `;
  const Text = styled.p`
    /* margin-top: 140px; */

    @media only screen and (max-width: 768px) {
      margin-top: 25px;
    }
  `;
  const Head = styled.div``;
  return (
    <div>
      <Contain>
        <div className="row m-0">
          <div className="col-md-6 mb-3 d-flex justify-content-center">
            <InnerCard className="">
              <Head className="d-flex text-center justify-content-center">
                <h4> Our Mission</h4>
              </Head>
              <Text>
                <li>
                  Our core Distinction is we allow students to work with you for
                  a period of 3 months before you decide to hire them. And
                  incase of an attrition, you will always have more students to
                  hire from
                </li>
                <br />
                <li>
                  We are assisting companies with the hiring process from the
                  initial research and matching stage till the final interview.
                  We help you understand your company culture, requirements and
                  assessment criteria. We also provide training on skills
                  required by your company
                </li>
                <br />
                <li>
                  We're not just looking for anyone who wants to work hard—we
                  want people who are ready to go above and beyond on your
                  project. This means they should be able to communicate
                  clearly, know how to work within a team dynamic, and be able
                  to stick with a project until the end.
                </li>
                <br />
                <li>
                  Doing all this isn't easy—it takes training students on what
                  skills are needed in your industry and how they can transfer
                  that knowledge into actionable tasks. That's why we let
                  students work on live project for three months before the
                  final offer is made!
                </li>
              </Text>
            </InnerCard>
          </div>
          <ColOne className="col-md-6 mb-3 d-flex justify-content-start">
            <Image src={pic1} alt="our_vision" />
          </ColOne>
        </div>
      </Contain>
    </div>
  );
}

export default Mission;
