import React from 'react'
import AboutHero from './AboutHero'
import Vision from './Vision'
import Mission from './Mission';
import OurCore from './OurCore';
import Team from './Team';
import styled from "styled-components";


function AboutMain() {
  const Contain = styled.div`
  @media only screen and (max-width: 768px){
        margin-top: 65px;
      }
  @media only screen and (max-width: 820px){
        margin-top: 65px;
      }
`
  return (
    <Contain>
        <AboutHero />
        <Vision />
        <Mission />
        <OurCore />
        <Team />
    </Contain>
  )
}

export default AboutMain