import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// import VerifiedIcon from '@mui/icons-material/Verified';
// import { Button } from 'react-bootstrap'
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import React, { useState,useEffect } from 'react';
// import login5 from "..//Login/login5.png";
import validator from 'validator';
import API from '../../Routes/api';
import Swal from 'sweetalert2';


function LoginData() {
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
   
    //Password Validation 
    const validate = (value) => {

        if (validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setErrorMessage('Strong Password')
        } else {
            setErrorMessage('Password Is Not Strong')
        }
    }
    //End of Password Validation 
    
    const handleSubmit = () => {
        console.log(1234);
        const formData = new FormData();
        formData.append('username', document.getElementById('email').value);
        formData.append('password', document.getElementById("password").value);
        console.log(formData);  
        API.post('/api/v1/login',formData).then(res=>{
            if(res.data.code===200){
                console.log(res.data);
                var app_url = res.data.data.redirect_url;
                var token = '?token='+res.data.data.access_token;
                var name = res.data.data.name !== ''? '&name='+res.data.data.name: '';
                var first_page = res.data.data.first_page !== ''? '&first_page='+res.data.data.first_page : '';                
                var url = app_url+token+name+first_page;
                window.location.href = url;
            }      
        }).catch(err=>{
            if(err.code==='ERR_BAD_REQUEST'){
            Swal.fire({
                title: err.response.data.status,
                text: err.response.data.message,
                icon: 'error',
                confirmButtonText: 'OK'
            })
            } 
        });         
    };

    const login = {
        justifyContent: 'center',
        textAlign: 'center'
    }
    const emailpass = {
        justifyContent: 'center',
        textAlign: 'center'
    }

    return (
        <Card className="textCenter textlogin">
            <Card.Body style={login}>
                <h3><b>Login</b></h3>
                <Card.Text>

                </Card.Text>
                <Button variant="danger" className='m-2'> {GoogleIcon && <GoogleIcon type={GoogleIcon} />} Login with Google</Button> 
                <Button variant="primary" className='m-2'> {LinkedInIcon && <LinkedInIcon type={LinkedInIcon} />} Login with LinkedIn </Button>
                <p style={{ fontSize: '13px', marginTop: '25px' }}>or login with email address{" "} </p>
                <hr style={{ marginBottom: '40px' }}></hr>

                <Form noValidate validated={validated} >
                    <Row className="mb-3">
                    </Row>

                    <Row className="mb-3" style={emailpass}>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Email-Id</Form.Label>
                            <Form.Control type="text" placeholder="Email-Id" id="email"/>                            
                        </Form.Group>
                    </Row>

                    <Row className="mb-3" style={emailpass}>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Password</Form.Label>                        
                            <Form.Control type="password" placeholder="Password" id="password"/>                    
                        </Form.Group>
                    </Row>

                    <Button type="button" onClick={handleSubmit}>LogIn</Button>
                    <p style={{ fontSize: '13px', marginTop: '15px' }}> Don't have an account?{" "}
                    <a href={process.env.REACT_APP_FRONTEND_URL+'signup'} ><b>SignUp</b></a> </p>
                    <p style={{ fontSize: '13px', marginTop: '25px' }}> <b>Note:</b> For better experience kindly view the application in Google Chrome{" "}</p>   
                </Form>

            </Card.Body>
            {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
        </Card>
    );
}

export default LoginData;