import React from 'react';
import styled from "styled-components";
import JobCard from './JobCard';
import JobCardDetails from './JobCardDetails';

function JobsList() {
  const Contain = styled.div`
    padding: 60px;

    @media only screen and (max-width: 768px) {
      padding: 35px;
    }
  `;
  return (
    <Contain>
      <h2 className='text-center pb-5'>Explore Jobs Opportunities</h2>
      <div className='row'>
        <div className='col-md-4'>
          <div className='left-card'>
            <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <button class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                <JobCard
                  jobname="Software Developer"
                  compname="AppLever(OPC) Tech"
                  loc="Bengaluru, India."
                  worktype="Full-Time"
                  stipend="3-4 LPA"
                  qualifi="BCA, BSc(CS), MCA, MSc(CS)"
                  nocandi="12"
                />
              </button>
              <button class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                <JobCard
                  jobname="Node JS Developer"
                  compname="QBH Solution PVT LTD"
                  loc="Bengaluru, India."
                  worktype="Full-Time"
                  stipend="Starts from 10000 INR"
                  qualifi="BCA, BSc(CS), MCA, MSc(CS)"
                  nocandi="16"
                />
              </button>
              <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                <JobCard
                  jobname="MERN STACK or PHP Freshers"
                  compname="JCaspTechnologies"
                  loc="Bengaluru, India."
                  worktype="Full-Time"
                  stipend=" 5000-7000 per month"
                  qualifi="BCA, BSc(CS), MCA, MSc(CS)"
                  nocandi="9"
                />
              </button>
              <button class="nav-link" id="v-pills-messages-tab-1" data-bs-toggle="pill" data-bs-target="#v-pills-messages-1" type="button" role="tab" aria-controls="v-pills-messages-1" aria-selected="false">
                <JobCard
                  jobname="ML Developer"
                  compname="Kotai Electronics"
                  loc="Bengaluru, India."
                  worktype="Full-Time, WFH"
                  stipend="15000 - 40000/month"
                  qualifi="BCA, BSc(CS), MCA, MSc(CS)"
                  nocandi="19"
                />
              </button>
              <button class="nav-link" id="v-pills-messages-tab-2" data-bs-toggle="pill" data-bs-target="#v-pills-messages-2" type="button" role="tab" aria-controls="v-pills-messages-2" aria-selected="false">
                <JobCard
                  jobname="Junior Android Developer"
                  compname="Stackfusion Private Limited"
                  loc="Bengaluru, India."
                  worktype="Full-Time"
                  stipend="15000 - 20000/month"
                  qualifi="BCA, BSc(CS), MCA, MSc(CS)"
                  nocandi="21"
                />
              </button>
              <button class="nav-link" id="v-pills-messages-tab-3" data-bs-toggle="pill" data-bs-target="#v-pills-messages-3" type="button" role="tab" aria-controls="v-pills-messages-3" aria-selected="false">
                <JobCard
                  jobname="Full Stack Engineer"
                  compname="Stackfusion Private Limited"
                  loc="Bengaluru, India."
                  worktype="Full-Time"
                  stipend="15000 - 20000/month"
                  qualifi="BCA, BSc(CS), MCA, MSc(CS)"
                  nocandi="14"
                />
              </button>
              <button class="nav-link" id="v-pills-messages-tab-4" data-bs-toggle="pill" data-bs-target="#v-pills-messages-4" type="button" role="tab" aria-controls="v-pills-messages-4" aria-selected="false">
                <JobCard
                  jobname="Deep Learning and AI"
                  compname="Stackfusion Private Limited"
                  loc="Bengaluru, India."
                  worktype="Full-Time"
                  stipend="15000 - 20000/month"
                  qualifi="BCA, BSc(CS), MCA, MSc(CS)"
                  nocandi="18"
                />
              </button>
              <button class="nav-link" id="v-pills-messages-tab-5" data-bs-toggle="pill" data-bs-target="#v-pills-messages-5" type="button" role="tab" aria-controls="v-pills-messages-5" aria-selected="false">
                <JobCard
                  jobname="Laravel Developer"
                  compname="Woxro Technology Solutions"
                  loc="Bengaluru, India."
                  worktype="Internship and Full-Time"
                  stipend="10000 - 20000/month"
                  qualifi="BCA, BSc(CS), MCA, MSc(CS)"
                  nocandi="6"
                />
              </button>
              <button class="nav-link" id="v-pills-messages-tab-6" data-bs-toggle="pill" data-bs-target="#v-pills-messages-6" type="button" role="tab" aria-controls="v-pills-messages-5" aria-selected="false">
                <JobCard
                  jobname="Associate Software Developer"
                  compname="Rudra Coresoft Technologies Private Limited"
                  loc="Bengaluru, India."
                  worktype="Internship"
                  stipend="15000 - 17000/month"
                  qualifi="BCA, BSc(CS), MCA, MSc(CS)"
                  nocandi="2"
                />
              </button>
            </div>
          </div>
        </div>
        <div className='col-md-8'>
          <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
              <JobCardDetails
                jobname="Software Developer"
                compname="AppLever(OPC) Tech"
                compdesc="Our Core Service is B2B Enterprise applications development for companies. We strive to help digitize every small & Medium enterprise using cloud/Saas Based smart applications. Simply put we help business to rock in their Business! We do this by focusing on enterprise Apps with the most relevant features, excellent user base support for easy adoption, and consultancy by talking in the client's semantics. We strive to provide the best value by aligning simplicity, quality, creativity, and functionality together without the scary price tag.
AppLever - We are a tech company with a business touch, you can trust for quality work."
                loc="Bengaluru, India."
                worktype="Full-Time"
                duration="NA"
                stipend="3-4 LPA"
                novaccancy="3"
                jobdesc="Should posses very good programming skills.
Should be able to understand and analyse the project requirements.
Should have the in depth knowledge in core Java concepts -basic java programming, java packages, classpaths & jar files, etc.
Should have good knowledge in Java Web Technologies like JSP, Servlets & how a java web application works.
Should have knowledge about Java Based Application Servers like Tomee.
Should have knowledge about REST Web Services - How to build one using Java?
Should have knowledge about frontend & backend - the overall modern application architecture.
Should have knowledge about Source Control Tools like Git, SourceTree.
Should have knowledge about Scrum Methodology of Software Development."
                eligiblity="Criteria 1: Should be ready to relocate and work from the office in Bangalore from 01/Feb. If they are already in Bangalore, no problem.

Criteria 2: Give preference to BCA, Bsc (Computer Science), MCA, MSc Computer Science Candidates rather than BE Grads.

Criteria 3: Communication should be very good. Clear communication and good listening skills are a must.

Criteria 4: Passed out from 2022 is ideal. 2021 also fine. 2023 is our last preference."
                skills="NA"
                roles="NA"
              />
            </div>
            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
              <JobCardDetails
                jobname="Node JS Developer"
                compname="QBH Solution PVT LTD"
                compdesc="Product and Service Based IT Company"
                loc="Bengaluru, India."
                worktype="Full-Time"
                duration="6 Months"
                stipend="Starts from 10000"
                novaccancy="4"
                jobdesc="We are looking for junior and Senior Node JS developer with Min 1 year of experience who having good knowledge in Angular amd Nest JS."
                eligiblity="Criteria 1: Should be ready to relocate and work from the office in Bangalore from 01/Feb. If they are already in Bangalore, no problem.

Criteria 2: Give preference to BCA, Bsc (Computer Science), MCA, MSc Computer Science Candidates rather than BE Grads.

Criteria 3: Communication should be very good. Clear communication and good listening skills are a must.

Criteria 4: Passed out from 2022 is ideal. 2021 also fine. 2023 is our last preference."
                skills="Node JS, Nest JS, Angular, HTML, CSS"
                roles="New functions Development and improve the existing function"
              />
            </div>
            <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
              <JobCardDetails
                jobname="MERN STACK or PHP Freshers"
                compname="JCaspTechnologies"
                compdesc="JCasp Technologies is representing a growing company which has xpertise lies in developing and deploying
cutting edge applications pertaining to e-commerce, mobile and other platforms. We also develop responsive
websites and conduct SEO and SMO. It has specialities in Responsive web designing, E-commerce
Development, Mobile Application Development, Social Media Apps, Corporate Branding, Wordpress, Magento,
Shopify, PHP, Angular JS, Node.JS, Bootstrap, Technology Consultation, Real Time Development, and Domain
&amp; Hosting Services."
                loc="Bengaluru, India."
                worktype="Full-Time"
                duration="1.5 years including internship of 4-6 months"
                stipend="5k-7k/month depending on candidate location. Salary would be between 2-3.25 LPA NET SALARY"
                novaccancy="4"
                jobdesc="---------- MERN STACK JOB DESCRIPTION ------------
- MERN stack developers should have the ability to translate Wireframes and PSD Designs into functional web apps.
- Binding of UI elements to JavaScript object models
- Creating RESTful services with Node.js
- MERN stack developer must have proven experience in front & back-end development
- Work in a cross-functional team to deliver a complete user experience
- Reactjs Nodejs developer must know MongoDB, Express, Node.js, & React.
- Create Unit and Integration tests to ensure the quality of code
- Be responsive to change requests and feature requests
- Write code that is cross-platform and cross-device compatible
- MERN fullstack developer must have the ability to wear many hats and learn new technologies quickly
- For MERN stack developer job, candidate must have good Knowledge of Node Debugging
- Understanding & proficiency in REST APIs.
- MERN stack developer should know Git Repository and Jira.
- Integration of data storage solutions [RDBMS, NoSQL DB].

--------PHP JOB DESCRIPTION---------
We are looking for a PHP Developer responsible for managing back-end services and the interchange of data
between the server and the users.
Your primary focus will be the development of all server-side logic, definition and maintenance of the central
database, and ensuring high performance and responsiveness to requests from the front-end.
You will also be responsible for integrating the front-end elements built by your co-workers into the application.
Therefore, a sound understanding of front-end technologies is necessary as well.
Key Responsiblities:-
● Measure and optimize the performance, scalability, and uptime of backend services
● Integration of user-facing elements developed by front-end developers
● Build efficient, testable, and reusable PHP modules
● Solve complex performance problems and architectural challenges
● Integration of data storage solutions may include databases, key-value stores, blob stores, etc.
● Manage a Team of 3-4 developers in PHP Frameworks and their Projects and Task Management
● Lead and support customer interactions with technical leadership and developers.
● Manage microservice data design and database implementation
● Demonstrate and promote a culture of engineering excellence
● Conduct design and code reviews and raise the bar on engineering rigor and standards
● Collaborate with product management, customer success, and support to deliver the best experience for
our customers
"
                eligiblity="● Understanding accessibility and security compliance
● Understanding fundamental design principles behind a scalable application
● Understanding of MVC design patterns
● User authentication and authorization between multiple systems, servers, and environments
● Integration of multiple data sources and databases into one system
● Creating database schemas that represent and support business processes
● Strong knowledge of PHP web frameworks such as Laravel, Yii, Symfony, Codeignitor
● Understanding the fully synchronous behavior of PHP
● Sound understanding of front-end technologies, such as JavaScript, HTML5, and CSS3
● Knowledge of object oriented PHP programming
● Strong knowledge of the common PHP or web server exploits and their solutions
● Familiarity with limitations of PHP as a platform and its workarounds
● Familiarity with SQL/NoSQL databases and their declarative query languages
● Proficient understanding of code versioning tools, such as Git
"
                skills="HTML, CSS, JAVASCRIPT, PHP, LARAVEL, MERNSTACK, MEANSTACK, Leadership, Management"
                roles="- MERN Fullstack eCommerce developer is responsible for writing reusable, testable, and efficient code
- Design and implementation of low-latency, high-availability, and performant applications
- Implementation of security and data protection
- Understanding of performance testing and optimization techniques
- MERN software developer must have good communication & interpersonal skills
- Ability to work independently with minimum supervision."
              />
            </div>
            <div class="tab-pane fade" id="v-pills-messages-1" role="tabpanel" aria-labelledby="v-pills-messages-tab-1">
              <JobCardDetails
                jobname="ML Developer"
                compname="Kotai Electronics"
                compdesc="We are automation and AI machine development company. Our solutions include smart city, industrial monitoring, etc.
Activity on Internshala"
                loc="Bengaluru, India."
                worktype="Full-Time, WFH"
                duration="NA"
                stipend="15000 - 40000/month"
                novaccancy="1"
                jobdesc="Should have a good understanding of Data analytics algorithms.
Data mining or extracting usable data from valuable data sources.
Using machine learning tools to select features, create and optimize classifiers.
Carrying out preprocessing of structured and unstructured data.
Enhancing data collection procedures to include all relevant information for developing analytic systems.
Processing, cleansing, and validating the integrity of data to be used for analysis.
Analyzing large amounts of information to find patterns and solutions.
Developing prediction systems and machine learning algorithms.
Presenting results in a clear manner.
Strong Coding Experience in python.
Knowledge of OpenCV is a plus."
                eligiblity="Strong Coding Experience in python.
Knowledge of OpenCV is a plus."
                skills="Python, OpenCV"
                roles="--"
              />
            </div>
            <div class="tab-pane fade" id="v-pills-messages-2" role="tabpanel" aria-labelledby="v-pills-messages-tab-2">
              <JobCardDetails
                jobname="Junior Android Developer"
                compname="Stackfusion Private Limited"
                compdesc="Stackfusion Private Limited is a technology product development company specializing in IoT and Artificial Intelligence.
Stackfusion has its product Parkzap which provides smart automated parking and vehicle security solutions to Smart
Cities, Malls, Offices, SEZs, Industrial Zones and Security Installations. Stackfusion also works on technology to redefine
the highway tolls and transportation sector in India by automated flow-through for vehicles and cashless payments."
                loc="Bengaluru, India."
                worktype="Full-Time"
                duration="NA"
                stipend="15000 - 20000 INR/month"
                novaccancy="3"
                jobdesc="
● Good written and verbal communication in English.
● Demonstrated experience with Java or Kotlin, and Android mobile programming.
● Experience with Android Studio.
● Familiarity with all phases of the software development life cycle (analysis, design, development, testing, defect
tracking/resolution, deployment, and field support).
● Experience with XML in designing layouts(Specially Constraint Layout)
● Familiarity with version control tools like GIT
● Familiarity with distributed cloud computing environments, modern computer software architectures, and
advanced software practices.
● Familiarity with Object-Oriented Application architecture and programming.
● Familiarity with Agile development methodologies (Scrum, Sprints, etc.)"
                eligiblity="--"
                skills="Java, Kotlin, Android Studio, Linux"
                roles="● Assist in the development of new Android mobile applications under the direction of a senior engineer.
● Design, code, unit test, debug, and document programs.
● Clearly and actively communicate the status of work and projects to stakeholders as needed.
● Diagnose and resolve software defects and problems.
● Meet time requirements for assignments, with minimal reminders.
● Provide work breakdown using standardized estimation techniques.
● Participate in work product reviews (e.g. code design).
● Support other legacies Android and Java applications as required"
              />
            </div>
            <div class="tab-pane fade" id="v-pills-messages-3" role="tabpanel" aria-labelledby="v-pills-messages-tab-3">
              <JobCardDetails
                jobname="Full Stack Engineer"
                compname="Stackfusion Private Limited"
                compdesc="Stackfusion Private Limited is a technology product development company specializing in IoT and Artificial Intelligence.
Stackfusion has its product Parkzap which provides smart automated parking and vehicle security solutions to Smart
Cities, Malls, Offices, SEZs, Industrial Zones and Security Installations. Stackfusion also works on technology to redefine
the highway tolls and transportation sector in India by automated flow-through for vehicles and cashless payments."
                loc="Bengaluru, India."
                worktype="Full-Time"
                duration="NA"
                stipend="15000 - 20000 INR/month"
                novaccancy="3"
                jobdesc="The Fullstack engineer will be responsible for designing and developing the complete back-end, server-side solution
along with the frontend, front-end and server deployment. She/He will create RESTful APIs for integration with
mobile apps and other third-party systems. The Fullstack engineer will be responsible for performance tuning, server
monitoring automation, and the security of the entire backend. She/he will also be working on machine learning
engineers to make provisions to enable programs running on big data sets."
                eligiblity="--"
                skills="Experience with

▪ Linux, Git, Python, Competitive programming for writing fast/optimized C, C++
▪ PostgreSQL, Websocket, and HTTP
▪ Django or any other similar web application frameworks(Node.js, Ruby on Rails, Spring)
▪ It is assumed you have basic experience of HTML, Javascript, React JS and similar web tools
▪ Network Programming
▪ Microservices Architecture
o Desired experience:
▪ Django Channels
▪ Django Rest Framework or similar tools.
▪ Designing RESTful APIs and Django/Jinja Templates or similar.
▪ Celery, RabbitMQ, Memcached
▪ Knowledge about ASGI and WSGI
▪ Docker and Kubernetes(Important)
▪ Shell scripting
▪ Having been part of a project which involves interaction with a mobile client
▪ Experience with OpenCV is a plus
▪ Exposure in languages like Go, C++, Erlang
▪ Server Load balancing
▪ Database replication
▪ Experience with MQTT or web-socket programming
▪ Django Channels
▪ CI tools
● Other aspects:
o 0-3 years of development experience
o Having a StackOverflow profile with a good reputation and Github portfolio is a plus
o People who have a knack to hack things and who are fast learners would be preferred
o Experience with writing unit tests of your code
o It doesn't matter if you’re coming fresh out from the school or college or stuck in a dead Tech-Stack
(PHP etc) or flunked your college. What is mandatory is to have a passion for working in a
fast-growing start-up, learning new things, and owning the product that you are working on.
o Do share any interesting project that makes you stand out"
                roles="● You will be expected to use your Samurai skills to design, forge and improve your Katana Sword (a.k.a
Django-PostgreSQL backend Server).
● Using Docker containers and Kubernetes you will quickly orchestrate your war efforts on AWS to handle
heavy loads of data.
● You will further support Mobile-Ninjas(Android Developers) and Hardware-Ninjas(IoT-Developers) to deliver
lightning-fast blows(replies) to incoming requests from Browsers, Mobile Apps, and IoT-edge-AI hardware.
● Along with this, you would have to write intelligent scripts to ingest Terabytes of data in seconds/minutes.
● You will primarily be coding in Python( Backend) and ReactJS (Frontend).
● You will be involved in the entire lifecycle of a project, including strategy, vision, design, coding, architecture,
deployment, optimization, etc, and will be chiefly expected to deliver clean, testable, and well-designed code
for the same.
● Your training and day to day work will also involve server spinning and other dev-ops operations and dealing
with Docker Containers in an AWS environment."
              />
            </div>

            <div class="tab-pane fade" id="v-pills-messages-4" role="tabpanel" aria-labelledby="v-pills-messages-tab-4">
              <JobCardDetails
                jobname="Deep Learning and AI"
                compname="Stackfusion Private Limited"
                compdesc="Stackfusion Private Limited is a technology product development company specializing in IoT and Artificial Intelligence.
Stackfusion has its product Parkzap which provides smart automated parking and vehicle security solutions to Smart
Cities, Malls, Offices, SEZs, Industrial Zones and Security Installations. Stackfusion also works on technology to redefine
the highway tolls and transportation sector in India by automated flow-through for vehicles and cashless payments."
                loc="Bengaluru, India."
                worktype="Full-Time"
                duration="NA"
                stipend="15000 - 20000 INR/month"
                novaccancy="3"
                jobdesc="--"
                eligiblity="1.Candidates with previous practical experience on any the ML frameworks like TensorFlow, PyTorch, etc. shall apply.
2. Candidates with a proven record of C++ programming, specifically in concepts like pointers, maps, vectors, etc shall
apply.
3. Candidates with no prior experience in ML/AI but who have demonstrated exceptional skills in backend/frontend
development will also be given equal preference.
4. Candidates with previous practical experience in deep-learning and developing full-stack web apps would be preferred."
                skills="OpenCV, Python, Machine Learning, C Programming, C++ Programming, Deep Learning, Gstreamer, MQTT, Kafka
Additional skills desirable:

A. Web development using any framework like Django, Go, Flask etc
B. Knowledge of Redis, RabbitMQ, Kafka
C. Experience/exposure in Nvidia tools like Deepstream, CUDA etc"
                roles="1. Develop ML models for vision intelligence.
2. Improve existing ML layers and modules.
3. Build data pipelines (knowledge of backend programming in Python is required).
4. Build frontend and backend applications as required.
5. Train models using Adversarial networks.
6. Optimize inferencing software to run at better frame rates.
7. Work on video pipelines and manipulate frames using OpenCV.
8. Design Gstreamer pipelines and work with Deepstream.
9. Handle Cloud server programming for training and inferencing of the models."
              />
            </div>

            <div class="tab-pane fade" id="v-pills-messages-5" role="tabpanel" aria-labelledby="v-pills-messages-tab-5">
              <JobCardDetails
                jobname="Laravel Developer"
                compname="Woxro Technology Solutions"
                compdesc="Woxro is a technology solutions company that builds highly scalable and localized apps. It is one of the premier web application firms in India with decorated clients in the hospitality, finance, and e-commerce industry."
                loc="Bengaluru, India."
                worktype="Internship and Full-Time"
                duration="NA"
                stipend="10000 - 20000 INR/month"
                novaccancy="NA"
                jobdesc="We are searching for a Laravel developer to build web applications for our company. In this role, you will design and create projects using Laravel framework and PHP, and assist the team in delivering high-quality web applications, services, and tools for our business.
To ensure success as a Laravel developer you should be adept at utilizing Laravel's GUI and be able to design a PHP application from start to finish. A top-notch Laravel developer will be able to leverage their expertise and experience of the framework to independently produce complete solutions in a short turnaround time."
                eligiblity="A degree in programming, computer science, or a related field."
                skills="Laravel Developer Requirements:
Experience working with PHP, performing unit testing, and managing APIs such as REST.
A solid understanding of application design using Laravel.
Knowledge of database design and querying using SQL.
Proficiency in HTML and JavaScript.
Practical experience using the MVC architecture.
A portfolio of applications and programs to your name.
Problem-solving skills and critical mindset.
Great communication skills.
The desire and ability to learn."
                roles="Laravel Developer Responsibilities:
Discussing project aims with the client and development team.
Designing and building web applications using Laravel.
Troubleshooting issues in the implementation and debug builds.
Working with front-end and back-end developers on projects.
Testing functionality for users and the backend.
Ensuring that integrations run smoothly.
Scaling projects based on client feedback.
Recording and reporting on work done in Laravel.
Maintaining web-based applications.
Presenting work in meetings with clients and management."
              />
            </div>

            <div class="tab-pane fade" id="v-pills-messages-6" role="tabpanel" aria-labelledby="v-pills-messages-tab-6">
              <JobCardDetails
                jobname="Associate Software Developer"
                compname="Rudra Coresoft Technologies Private Limited"
                compdesc="Rudra Coresoft is a fast growing, product engineering services startup focussed on delivering business outcomes for our customers by:
                Building innovative end to end solutions across the complete product life cycle.
                Simple, cost effective, data driven delivery/execution model.
                Leveraging domain expertise sourced either in-house or by acquiring it rapidly by working closely with our customers.
               The Company has been in existence since the year 2020 in the form of it's predecessor Rudra IT Software. We work across the full stack with additional specialization in domains such as Data Engineering, Mobility and IoT. We have cultivated expertise on multiple discliplines. At a highlevel, we have dedicated teams for full stack development, Data Engineering and Mobile Application development.
               The Full stack team is well versed with various development frameworks, protocols and tools across the full-stack. We cover the entire full tech stack spectrum: Web & mobile application development to database design, server development(enterprise frameworks or open source) & deployment to the cloud. The Mobile development team has expertise on PWA (Progressive web application), Native and hybrid mobile application development with special emphasis on security. Dedicated department for Data engineering provides services on Big Data and ETL technologies also exists a seperate vertical."
                loc="Bengaluru, India."
                worktype="Internship"
                duration=" 4 Months"
                stipend="15000 - 17000 INR/month"
                novaccancy="2"
                jobdesc="Who can apply

                1. Those who are from Bangalore and willing to attend F2F Interview at our office
                2. Excellent oral and written communication skills
                3. Good logical and analytical thinking skills
                4. Only graduates (B.E, BSc, or equivalent) from computer science or information science (preferred)
                5. Proficient in Python and SQL
                6. Expertise in Django or any other language/framework is a bonus
                
                
                Key responsibilities/Work Profile:
                
                Candidate shall
                1. Work with Python/Django framework developing web applications
                2. Integrate backend services/API with front-end applications.
                3. Learn and Develop Sleek Web Front-end applications
                
                or
                
                1. Work in the domain of data engineering
                2. Work with Python, SQL, Airflow, Pyspark, and Hadoop
                3. Data analytics
                
                Probation:
                Duration: 4 months
                Salary during probation: 15,000 - 17,000 /month
                Salary shall be incremented as per Performance if the candidate clears the probationary period with passing criteria
                "
                eligiblity="1. Those who are from Bangalore and willing to attend F2F Interview at our office
                2. Excellent oral and written communication skills
                3. Good logical and analytical thinking skills
                4. Only graduates (B.E, BSc, or equivalent) from computer science or information science (preferred)
                5. Proficient in Python and SQL
                6. Expertise in Django or any other language/framework is a bonus"
                skills="Associate Software Developer:
                Java or Python and SQL"
                roles="Associate Software Developer:
                Candidate shall
                1. Work with Python/Django framework developing web applications
                2. Integrate backend services/API with front-end applications.
                3. Learn and Develop Sleek Web Front-end applications
                
                or
                
                1. Work in the domain of data engineering
                2. Work with Python, SQL, Airflow, Pyspark, and Hadoop
                3. Data analytics
                "
              />
            </div>
          </div>

        </div>
      </div>
    </Contain>


  )
}

export default JobsList