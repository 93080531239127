import React from 'react'
import styled from 'styled-components'

function ProjectCard() {
    const Contain = styled.div`
        margin-top: 80px !important;
    @media only screen and (max-width: 768px) {
        margin-top: 0px !important;
    }
    @media only screen and (max-width: 820px) {
        margin-top: 0px !important;
    }
    `
    const Bor = styled.div`
    border: 2px solid red; 
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    padding: 20px;
    width: 90%;
`
    const Text = styled.p`
    font-weight: 600;
    font-size: 20px;
    display: flex;
    justify-content: center;
`
    const Sub = styled.p`
        font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    `
    return (
        <div>
            <div>
                <Contain className="row m-0 p-5 ">
                    <div className="col-md-3 mb-4 d-flex justify-content-center">
                        <Bor>
                            <Text>PRITHVI.AI</Text>
                            <Sub>AI/ML, django, and Deep learning</Sub>
                        </Bor>
                    </div>
                    <div className="col-md-3 mb-4 d-flex justify-content-center">
                        <Bor>
                            <Text>FOX TRADING</Text>
                            <Sub>ML and Prediction algorithms</Sub>
                        </Bor>
                    </div>
                    <div className="col-md-3 mb-4 d-flex justify-content-center">

                        <Bor>
                            <Text>THREAT PRISM</Text>
                            <Sub>VUE JS, LARAVEL </Sub>
                        </Bor>
                    </div>
                    <div className="col-md-3 mb-4 d-flex justify-content-center">
                        <Bor>
                            <Text>MZAALO</Text>
                            <Sub>Go-to-market strategy and Community building</Sub>
                        </Bor>
                    </div>
                    <div className="col-md-3 mb-4 d-flex justify-content-center">
                        <Bor>
                            <Text>LANGUIFY</Text>
                            <Sub>Automatic speech Recognition modelling with AI</Sub>
                        </Bor>
                    </div>
                    <div className="col-md-3 mb-4 d-flex justify-content-center">
                        <Bor>
                            <Text>Personifwy</Text>
                            <Sub>Web crawling and Web Scraping with Data Analytics</Sub>
                        </Bor>
                    </div>
                    <div className="col-md-3 mb-4 d-flex justify-content-center">
                        <Bor>
                            <Text>BOBBLE.AI</Text>
                            <Sub>Mobile Application Using Kotlin & Firebase</Sub>
                        </Bor>
                    </div>
                    <div className="col-md-3 mb-4 d-flex justify-content-center">
                        <Bor>
                            <Text>ANYVOICE</Text>
                            <Sub>Growth Marketing and customer acquisition strategies</Sub>
                        </Bor>
                    </div>

                </Contain>
            </div>
        </div>
    )
}

export default ProjectCard