import React from "react";
import AcademyHero from "./Hero/AcademyHero";
import AcademyCardhold from "./Hero/AcademyCardhold";
import AcaTestimonial from "./Testimonial/AcaTestimonial";
import AccTeamMain from "./TeamMember/AccTeamMain";
import AcaFaqMain from "./FAQ/AcaFaqMain";
import AcademyWhyUs from "./WhyChoose/AcademyWhyUs";
import WhatWeDoMain from "./WhatWeDo/WhatWeDoMain";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import SchoolIcon from "@mui/icons-material/School";
import one from "../../../Assets/Academy/tab1.jpg";
import two from "../../../Assets/Academy/Aca/aca11.jpg";
import three from "../../../Assets/Academy/Aca/aca22.jpg";
import four from "../../../Assets/Academy/Aca/aca33.jpg";


function AcademyMain() {
  return (
    <div>
      <AcademyHero
        carotextone="Take your Business Online and to the Next Level with Us"
        // carosubone="We are your partner in your success "
      />
      <AcademyCardhold
        cardIconone={<QueryStatsIcon />}
        cardHeadone="Business Growth"
        cardSubone="Launch your website 
              Build , Deploy and   Host"
        cardIcontwo={<TipsAndUpdatesIcon />}
        cardHeadtwo="Marketing Support "
        cardSubtwo="Support with Digital Marketing"
        cardIconthree={<SchoolIcon />}
        cardHeadthree="Business Growth"
        cardSubthree="Business Growth 
              Take your business online "
      />
      <WhatWeDoMain 
        tabOne="Complete the Registration Process"
        tabTwo="Build and Deploy"
        tabThree="Grow your Business"
        tabFour="Multiple Business Solutions"
        picOne={one}
        picTwo={two}
        picThree={three}
        picFour={four}
        tabheadone="Complete the Registration Process"
        tabonepoint1="Update your details such as first name, last name, Company name, email id"
        tabonepoint2="Select from the various pre-built website templates and  update the fields as directed "
        tabonepoint3="Select a template of your choice and Preview website you have created and contact us for any further support needed with website hosting and launching"

        tabheadtwo="Build and Deploy"
        tabsubtwo="We are your partner in your growth journey. Launch your website in under 2 hours "
        tabtwopoint1="Decide your business or domain name that you want to be known as "
        tabtwopoint2="We shall take care of website Hosting, deployment and management "
        tabtwopoint3="Not just that, but we also create your Business presence online to help take your business to all parts of the world"

        tabheadthree="Grow your Business"
        tabthreepoint1="Get the details of your customers’ journey from visit to sale to product delivery "
        tabthreepoint2="Select from the suite of our product offerings that you want to integrate with your website"
        tabthreepoint3="We also offer Digital Marketing support to help expand your business "

        tabheadfour="Multiple Business Solutions"
        tabsubfour="Select the options that your business needs with our platform and pay for what you need "
        tabfourpoint1="Make your business stand out by adding certifications and project with our partnered companies "
        tabfourpoint2="Integrate LMS & Content Management System to your website and manage students, teachers and partners from anywhere on one single platform."
        tabfourpoint3="Create personalised learning experience by offering Student Dashboard, Mentor Dashboard."
      />
      <AcademyWhyUs 
        WhyBoxheadOne = "1 Stop Solution "
        WhyBoxsubOne="We are a one stop solution from building to hosting to selling and managing website"

        WhyBoxheadTwo="Your Growth Partner   "
        WhyBoxsubTwo="Identify your growth tool requirements and deploy it within 24 hours with our solutions "

        WhyBoxheadThree="Cost Effective and Secure"
        WhyBoxsubThree="We offer flexible plans. Forecast your earnings and plan your expenditures"

        WhyBoxheadFour="Round the clock Support"
        WhyBoxsubFour="Quick response time to all your queries and concerns"
      />
      <AccTeamMain />
      <AcaTestimonial
        testinameOne="Michael Harper" 
        testinameTwo="Amelia Daniel"
        testinameThree="Alex Mason"
      />
      <AcaFaqMain />
    </div>
  );
}

export default AcademyMain;
