import React from "react";
import styled from "styled-components";
import CarouselCard from "../Extra/Carousel_Card";

function Success() {
  const Contain = styled.div`
    background-image: linear-gradient(to right, #54006b, #cc123d);
    padding: 35px;
    height: 600px;
    @media only screen and (max-width: 768px) {
      padding: 0px;
      height: auto;
    }
    @media only screen and (max-width: 820px) {
      padding: 0px;
      height: auto;
    }
  `;
  const Head = styled.p`
    color: #fff;
    margin: 20px !important;
    font-size: 25px;
    font-weight: 600;
    @media only screen and (max-width: 768px) {
      margin: 0px !important;
    }
    @media only screen and (max-width: 820px) {
      margin: 0px !important;
    }
  `;
  return (
    <Contain>
      <div className="text-center">
        <Head>Our Success Stories </Head>
      </div>
      <div className="row m-0">
        <div className="col-12 col-sm-12  col-lg-4 d-flex justify-content-center">
          <CarouselCard
            StudentName="Rahul kalara"
            Clg="LPU/ Simplilearn"
            innerContent="I had a very good on job learning experience with Movidu. I started as an intern on an IC role and later on went to managing a team lead. I bagged my current job because of my learnings here. The work environment is great and there is flexibility in working hours. The people are very friendly and helpful in providing their inputs for the work."
          />
        </div>
        <div className="col-12 col-sm-12  col-lg-4 d-flex justify-content-center">
          <CarouselCard
            StudentName="Shubham Kumar"
            Clg="MIT/ Razorpay"
            innerContent="Movidu provided me with good exposure to the real world problems and helped me to hone my skills in solving them. The team environment was very motivating, collaborative and fun. The management was supportive and provided ample guidance throughout the internship."
          />
        </div>
        <div className="col-12 col-sm-12  col-lg-4 d-flex justify-content-center">
          <CarouselCard
            StudentName="Amit Raj"
            Clg="SRM/ Amazon"
            innerContent="The management at Movidu is very accommodating and they give you the freedom to learn from mistakes. The environment is also very friendly, you can easily make friends at work."
          />
        </div>
      </div>
    </Contain>
  );
}

export default Success;
