import React from 'react';
import styled from "styled-components";
import pic from "../../Assets/About_Page/Hero.svg";

function JobHero() {
    const Contain = styled.div`
    background-image: linear-gradient(to right, #54006b, #cc123d);
    padding: 60px;
    @media only screen and (max-width: 768px){
      padding: 20px;
        }
        @media only screen and (max-width: 820px){
      padding: 20px;
        }
  `
  const Image = styled.img`
        margin-top: 100px;
        height: 470px;

        @media only screen and (max-width: 768px){
          height: 350px;
          margin-top: 20px;
        }
  `

  const Text = styled.p`
      color: #fff;
      margin-top: 120px;
      font-size: 20px;

      @media only screen and (max-width: 768px){
        margin-top: 40px;
      }
  `
  return (
    <Contain>
      <div className="row m-0">
        <div className="col-md-6 ">
          <Text>
          At Movidu, we believe that acquiring the right talent is the most important key to growth.
            <br />
            <br />
            We're not just looking for anyone who wants to work hard—we want people who are ready to go above and beyond on your project. This means they should be able to communicate clearly, know how to work within a team dynamic, and be able to stick with a project until the end.
          </Text>
        </div>
        <div className="col-md-6 d-flex justify-content-center">
          <Image src={pic} alt="hero" className="img-fluid" />
        </div>
      </div>
    </Contain>
  )
}

export default JobHero