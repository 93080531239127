import React from "react";
import Footer from "./index";
import Icon from "./styles/iconMain";
import Term from "../../../../Assets/Documents/Terms.pdf"
import Privacy from "../../../../Assets/Documents/Privacy.pdf"
import Use from "../../../../Assets/Documents/T&C.pdf"


export function FooterContainer() {
      const handleEmailMe = () => {
        window.open("mailto:support@inmovidutech.com")
    }
  return (
    <Footer>
      <Footer.Wrapper>
        <Footer.Row>
          <Footer.Column>
            <Footer.Title>Explore</Footer.Title>
            <Footer.Link href="/about ">
              About Us
            </Footer.Link>
            <Footer.Link href="/projectMain">Project</Footer.Link>
            {/* <Footer.Link href="/learningpartner">Sponsors</Footer.Link>
            <Footer.Link href="/blog">Blog</Footer.Link> */}
          </Footer.Column>
          <Footer.Column>
            <Footer.Title>Documents</Footer.Title>
            <Footer.Link href={Term} target="_blank">Terms & Condition</Footer.Link>
            <Footer.Link href={Privacy} target="_blank">Privacy Policies</Footer.Link>
            <Footer.Link href={Use} target="_blank">Terms of Use</Footer.Link>
          </Footer.Column>
          <Footer.Column >
            <Footer.Title>Contact Us</Footer.Title>
            <Footer.Link href="" className="d-flex" onClick={handleEmailMe}>
            <div>
            <Icon className="far fa-envelope"/>
            </div>
            <footer>
            support@movidu.in
            </footer>
            </Footer.Link>
            <Footer.Link href=" " className="d-flex">
            <div>
            <Icon className="fas fa-phone"/>
            </div>
            <div>
            +918296507779 
            </div>
            </Footer.Link>
            <Footer.Link href=" " className="d-flex">
            <div>
            <Icon className="fab fa-whatsapp"/>
            </div>
            <div>
            +918296507779
            </div>
            </Footer.Link>
          </Footer.Column>
          <Footer.Column>
            <Footer.Title>Social</Footer.Title>
            <Footer.Link
            //  href="https://www.facebook.com/checkpoint/828281030927956/?next=https%3A%2F%2Fwww.facebook.com%2Finmovidu%2F" 
            target="_blank">
              <Icon className="fab fa-facebook-f" />
              Facebook
            </Footer.Link>
            <Footer.Link 
            // href="https://www.instagram.com/inmovidutech/" 
            target="_blank">
              <Icon className="fab fa-instagram" />
              Instagram
            </Footer.Link>
            <Footer.Link 
            // href="https://www.linkedin.com/company/inmovidu-technologies-pvt-limited/mycompany/" 
            target="_blank">
              <Icon className="fab fa-linkedin" />
              Linkedin
            </Footer.Link>
          </Footer.Column>
        </Footer.Row>
      </Footer.Wrapper>
    </Footer>
  );
}
