import React from "react";
import styled from "styled-components";
import AcademyCards from "./AcademyCards";

import { Container } from "react-bootstrap";

function AcademyCardhold(props) {
  const Contain = styled.div``;
  return (
    <Contain>
      <Container>
        <div className="row m-0">
          <div className="col-md-4" style={{zIndex: 1}}>
            <AcademyCards
              icon={props.cardIconone}
              heading={props.cardHeadone}
              sub={props.cardSubone}
            />
          </div> 
          <div className="col-md-4" style={{zIndex: 1}}>
            <AcademyCards
              icon={props.cardIcontwo}
              heading={props.cardHeadtwo}
              sub={props.cardSubtwo}
            />
          </div>
          <div className="col-md-4" style={{zIndex: 1}}>
            <AcademyCards
              icon={props.cardIconthree}
              heading={props.cardHeadthree}
              sub={props.cardSubthree}
            />
          </div>
        </div>
      </Container>
    </Contain>
  );
}

export default AcademyCardhold;
