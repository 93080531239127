import React from "react";
import CompanyHero from "./CompanyHero";
import CompanyMore from './CompanyMore';
import one from './../../Assets/CompanyPage/startup.jpg'
import two from './../../Assets/CompanyPage/msme.jpg'
import three from './../../Assets/CompanyPage/largemsme.jpg'

import styled from "styled-components";
import WhatWeDoMain from "../Learning Partners/Academy/WhatWeDo/WhatWeDoMain";

function CompanyMain() {
  const Contain = styled.div`
  @media only screen and (max-width: 768px){
        margin-top: 20px;
      }
  @media only screen and (max-width: 820px){
        margin-top: 20px;
      }
`
  return (
    <Contain>
      <CompanyHero />
      {/* <CompanyTab 
        tabval1={<TabOne />}
        tabval2={<TabTwo />}
        tabval3={<TabThree/>}
        name1="For Startup"
        name2="For MSME"
        name3="For Large Company"
      /> */}
      <WhatWeDoMain 
        tabOne="For Startup"
        tabTwo="For MSME"
        tabThree="For Large Company"
        // tabFour="Multiple Business Solutions"
        picOne={one}
        picTwo={two}
        picThree={three}
        tabheadone="For Startup"
        tabonepoint1="Build your MVP : Share your project. We shall have students build it for you"
        tabonepoint2="Testing your Prototype: Launch it with our student Community and get feedback"
        tabonepoint3="Marketing : Extensive Branding with students across India"

        tabheadtwo="For Medium Sized Companies"
        tabtwopoint1="Build your Talent Pool : Convert your hiring Requirements into projects and create efficiencies for your HR Team"
        tabtwopoint2="Trained Resources : Our team of experts trains the resources for your company"
        tabtwopoint3="Work with candidates for free for 1 Month before you hire them"

        tabheadthree="For Large Enterprises"
        
        tabthreepoint1="Hire candidates who are ready to deploy"
        tabthreepoint2="Training support provided by our mentors"
        tabthreepoint3="Review their assignment, offer feedback, meet your team virtually and guide them to ensure that they are all on the same page."

        tabheadfour="Multiple Business Solutions"
        tabsubfour="Select the options that your business needs with our platform and pay for what you need "
        tabfourpoint1="Make your business stand out by adding certifications and project with our partnered companies "
        tabfourpoint2="Integrate LMS & Content Management System to your website and manage students, teachers and partners from anywhere on one single platform."
        tabfourpoint3="Create personalised learning experience by offering Student Dashboard, Mentor Dashboard."
      />
      {/* <CompanyProfile /> */}
      <CompanyMore />
    </Contain>
  );
}

export default CompanyMain;