import React from "react";
import styled from "styled-components";
import pic from "../../../Assets/Blogs/innerblog.svg";

function BlogInfo() {
  const Contain = styled.div`
    background-image: linear-gradient(to right, #54006b, #cc123d);
    /* padding: 300px; */
    padding: 120px 80px;

    @media only screen and (max-width: 921px) {
      padding: 100px 32px;
    }
  `;
  const Head = styled.h2`
    color: #fff;
    margin-bottom: 60px;
  `;
  const Sub = styled.p`
    color: #fff;
  `;
  return (
    <Contain>
      <div className="row m-0">
        <div className="col-md-7 mt-5">
          <Head>Blogs</Head>
          <Sub>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti
            dolor doloremque maxime quod quisquam adipisci dolorum modi ipsa
            eius? Eum tenetur obcaecati ab maiores quas iste repudiandae soluta
            earum! Illo.
          </Sub>
        </div>
        <div className="col-md-5 mt-5">
          <img src={pic} alt="info_blog" />
        </div>
      </div>
    </Contain>
  );
}

export default BlogInfo;
