import React from 'react';
import styled from "styled-components";
import JobHero from './JobHero';
import JobsList from './JobsList';

function Jobs() {
    const Contain = styled.div`
  @media only screen and (max-width: 768px){
        margin-top: 65px;
      }
  @media only screen and (max-width: 820px){
        margin-top: 65px;
      }
`
  return (
    <Contain>
        <JobHero/>
        <JobsList/>
    </Contain>
  )
}

export default Jobs