import "./CarouselStyle.css";

import React from "react";
import Carousel from "react-elastic-carousel";
import styled from "styled-components";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1 },
  { width: 768, itemsToShow: 1 },
  { width: 1200, itemsToShow: 1 },
];

const Head = styled.p`
  font-size: 32px;
  color: #ca3040;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 820px) {
    font-size: 16px;
  }
`;
  const InnerCol = styled.div`
      padding: 100px 50px;

      @media only screen and (max-width: 768px) {
      padding-top: 100px;
      padding-right: 0px;
      padding-left: 0px;
      /* margin-bottom: 30px */
    }
    @media only screen and (max-width: 820px) {
      padding: 60px 0px;
    }
  `
const Carou = styled.div`
  padding: 40px;
  color: #707070;
  font-size: 20px;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 820px) {
    font-size: 12px;
  }
`;

function CompanyCarousel() {
  const Item = styled.div`
    /* display: flex;
    justify-content: start;
    align-items: center; */
    height: 450px;
    width: 100%;
    /* padding: 10px; */
    /* background-color: #00008B; */
    color: #fff;
    display: grid;
    align-items: center;
    /* margin: 0 15px; */
    /* font-size: 4em; */
    @media only screen and (max-width: 768px) {
      height: 330px;
    }
    @media only screen and (max-width: 820px) {
      height: 330px;
    }
  `;

  return (
    <>
      <div>
        <Carousel breakPoints={breakPoints}>
          <Item>
            <Carou>
              <Head>You Need The Right Candidates, We Have Them</Head>
              <p style={{ color: "rgb(72 68 68)" }}>
                When you're looking for new hires, you need to make sure that
                your candidate pool is as diverse as possible. That's why
                [Inmovidu] has trained resources, freshers and experienced
                professionals who can work with you before you hire them. We'll
                also help review their resume and references before you offer a
                job.
              </p>
            </Carou>
          </Item>
          <Item>
            <Carou>
              <Head>
                Launch Internships via our platform to students across 4000+
                colleges Looking for Interns? We have the solution!
              </Head>
              <p style={{ color: "rgb(72 68 68)" }}>
                With a platform that connects employers with students across
                4000+ colleges, we can help you find the best and brightest
                college talent. We've been providing this service since [2+Years
                ], and have trained over [1.5 Lakh] resources in various fields.
              </p>
            </Carou>
          </Item>
          <Item>
            <Carou>
              <Head>
                Connecting High Potential Students to the Right Opportunity
              </Head>
              <p style={{ color: "rgb(72 68 68)" }}>
                Our platform makes it easy to work with your new hires before
                they become part of your team. You'll get access to their
                resumes, cover letters, and portfolios, projects—all before
                they're even hired!
              </p>
            </Carou>
          </Item>
          <Item>
            <Carou>
              <Head>Learn.Build.Grow</Head>
              <p style={{ color: "rgb(72 68 68)" }}>
                It's time to take control of your hiring process. Start using
                our platform today! we train and mentor the students, build
                their competencies by having them work on live project, and grow
                your business with the right resources.
              </p>
            </Carou>
          </Item>
        </Carousel>
      </div>
    </>
  );
}

export default CompanyCarousel;
