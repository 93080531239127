import React from 'react'
import ProjectCard from './ProjectCard'

function ProjectPage() {
  return (
    <div>
      <ProjectCard />
    </div>
  )
}

export default ProjectPage