import React from "react";
// import pic from "../../../../Assets/Academy/tab1.jpg";
import styled from "styled-components";

function WWDTab1(props) {
    const KMore = styled.a`
    background-image: linear-gradient(to right, #fea902, #ff7609);
    border-radius: 25px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 10px;
    text-decoration: none;
    margin-top: 15px;
  
    @media only screen and (max-width: 768px) {
          margin: 10px;
          margin-bottom: 90px;
  
    }
    @media only screen and (max-width: 820px) {
      margin: 10px;
      margin-bottom: 90px;
    }
  `;
  const Image = styled.img`
    height: 320px;
    width: 600px;
  `
  const Content = styled.div` 
    height: 240px;
  `
  return (
    <div>
      <div className="row m-0">
        <div className="col-md-5 mb-4">
          <Image src={props.pic} alt="person" />
        </div>
        <div className="col-md-7 mb-4 d-flex flex-column justify-content-center">
        <Content>
          <h5>{props.tabhead}</h5>
          <h6>{props.tabsub}</h6>
          <ol >
            <li style={{listStyleType: "disclosure-closed"}}>
              {props.tabpoint1}
            </li>
            <li style={{listStyleType: "disclosure-closed"}}>
            {props.tabpoint2}
            </li>
            <li style={{listStyleType: "disclosure-closed"}}>
            {props.tabpoint3}
            </li>
            {/* <li style={{listStyleType: "disclosure-closed"}}>
            {props.tabpoint4}
            </li> */}
          </ol>
          </Content>
          <div className="d-flex justify-content-center">
          <KMore
            className="btn"
            onClick={() => {
              window.location.pathname = "/";
            }}
          >
            Explore More
          </KMore>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default WWDTab1;
