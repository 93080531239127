import React from "react";
import styled from "styled-components";
import pic from "../../../Assets/project.svg";

function ProjectsMain() {
  const Contain = styled.div`
    background-image: linear-gradient(to right, #54006b, #cc123d);
    padding: 15px;
  `;
  const Head = styled.p`
    color: #fff;
    margin: 20px !important;
    font-size: 25px;
    font-weight: 600;
  `;
  const Image = styled.img`
    height: 500px;
    margin-top: 15px;
    @media only screen and (max-width: 768px) {
      ${'' /* padding: 10px; */}
      height: 430px;
      margin-top: -100px !important;
    }
    @media only screen and (max-width: 820px) {
      ${'' /* padding: 10px; */}
      height: 430px;
      margin-top: -100px !important;
    }
  `;
  const Text = styled.div`
    color: #fff;
  `;
  const LeftCol = styled.div`
    /* padding: 0px 80px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item: center;

    @media only screen and (max-width: 768px) {
      padding: 10px;
      margin-bottom: 0px !important;
    }
    @media only screen and (max-width: 820px) {
      padding: 10px;
      margin-bottom: 0px !important;
    }
  `;
  const List = styled.li`
    list-style-type: disc;
    font-size: 16px;
    /* line-height: 100%; */
    margin-bottom: 10px;
  `;
  const Para = styled.p`
    display: flex;
    justify-content: center;
    align-item: center;
    /* padding: 150px; */
    padding: 60px 50px;
    @media only screen and (max-width: 921px) {
      padding: 15px;
    }
  `;
  const KMore = styled.span`
    background-image: linear-gradient(to right, #fea902, #ff7609);
    margin: 25px;
    border-radius: 0;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  `;
  const Title = styled.p`
    font-size: 19px;
    font-weight: 600;
`;
  return (
    <Contain>
      <Head className="text-center">
        <div>Projects</div>{" "}
      </Head>
      <div className="row m-0 d-flex ">
        <LeftCol className="col-md-5">
          <Text>
            <p>
              <Title>
                Ready to transform the way you hire and get the best people?
                <br />
                <br />
                We're here for you.
                <br />
                At Movidu, we believe that every company has unique needs and
                goals, which is why we take the time to listen to your needs and
                develop customized solutions for your team. Our program allows
                you to:
              </Title>
              <Para>
                <ol>
                  <List>
                    Hire students who are trained to work on your project.
                  </List>
                  <List>
                    Training and mentorship supported to be provided by our
                    mentors during the training period
                  </List>
                  <List>
                    Once the training is over, hire from our pool of people
                    specially trained for your projects
                  </List>
                  <List>
                    Review their assignment, offer feedback, meet your team
                    virtually and guide them to ensure that they are all on the
                    same page
                  </List>
                </ol>
              </Para>
            </p>
          </Text>
        </LeftCol>
        <div className="col-md-7 d-flex justify-content-end">
          <Image src={pic} alt="project" className="img-fluid" />
        </div>
      </div>
      <div className="text-center">
        <KMore className="btn" onClick={() => {
              window.location.pathname = "/projectMain";
            }}
            >Know More</KMore>
      </div>
    </Contain>
  );
}

export default ProjectsMain;
