import { Tab, Tabs } from "react-bootstrap";
import React, { useState } from "react";
import WWDTab1 from "./WWDTab1";
import "../../../../App.css"
// import styled from "styled-components";


function WhatWeTab(props) {
  const [key, setKey] = useState("tab1");
  
  return (
    <div id="wwd2" className="m-0">
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 border-0"
      >
        <Tab
          eventKey="tab1"
          title={props.tab1}
          id="tabchange"
          // style={{
          //     fontWeight: "800"
          // }}
        >
          <WWDTab1
            pic={props.picOne}
            tabhead={props.tabheadone}
            tabpoint1={props.tabonepoint1}
            tabpoint2={props.tabonepoint2}
            tabpoint3={props.tabonepoint3}
            
            // tabpoint4={props.tabonepoint4}
          />
        </Tab>
        <Tab eventKey="tab2" title={props.tab2} id="tabchange" style={{width: "100% !important"}}>
          <WWDTab1
            pic={props.picTwo}
            tabhead={props.tabheadtwo}
            tabsub={props.tabsubtwo}
            tabpoint1={props.tabtwopoint1}
            tabpoint2={props.tabtwopoint2}
            tabpoint3={props.tabtwopoint3}
          />
        </Tab>
        <Tab eventKey="tab3" title={props.tab3} id="tabchange">
          <WWDTab1
            pic={props.picThree}
            tabhead={props.tabheadthree}
            tabsub={props.tabsubthree}
            tabpoint1={props.tabthreepoint1}
            tabpoint2={props.tabthreepoint2}
            tabpoint3={props.tabthreepoint3}
          />
        </Tab>
        <Tab eventKey="tab4" title={props.tab4} id="tabchange">
          <WWDTab1
            pic={props.picFour}
            tabhead={props.tabheadfour}
            tabsub={props.tabsubfour}
            tabpoint1={props.tabfourpoint1}
            tabpoint2={props.tabfourpoint2}
            tabpoint3={props.tabfourpoint3}
          />
        </Tab>
      </Tabs>
    </div>
  );
}

export default WhatWeTab;
