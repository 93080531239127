import React from 'react'

import AcademyMain from './Academy/AcademyMain';

function LearningMain() {
  return (
    <div>
    <AcademyMain />
    {/* <Comsoon/> */}
        {/* <LearningHero />
        <CompanyTab 
        tabval1={<LearningTabOne />}
        tabval2={<LearningTabOne />}
        tabval3={<LearningTabOne />}
        name1="College"
        name2="Academy"
        name3="Mentors"
        /> */}
    </div>
  )
}

export default LearningMain