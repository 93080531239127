import React from 'react';
import pic1 from "../../Assets/Icon-money-check-alt.png";

function JobCard(prop) {
  return (
    
    <div class="card-opp">
                            <h5>{prop.jobname}</h5>
                            <h6>{prop.compname}</h6>
                            <div className="opp-loc">{prop.loc} <span>{prop.worktype}</span></div>
                            <div className="opp-sal"><img src={pic1} width="30" className='pe-1' alt=""/>
                            {prop.stipend}
                            </div>
                            <div className="opp-eli"><span><b>Qualification</b></span> {prop.qualifi}</div>
                            <div className="d-flex justify-content-between">
                                <div className="opp-lastup">1 day ago</div>
                                <div className="opp-appl">{prop.nocandi} applicants</div>
                            </div>
                        </div>
  )
}

export default JobCard