import React from "react";
import styled from "styled-components";
import Pic from "../../../../Assets/Academy/man.png";

function AcaTestimonialContent(props) {
  const Contain = styled.div`
    background-color: #fff;
    padding: 20px;
  `;
  const Image = styled.img`
    border-radius: 50px;
  `;

  return (
    <Contain>
      <div className="text-center">
        <Image src={Pic} alt="Testimonial" className="img-fluid" />
        <h5 className="mb-0">{props.testiname}</h5>
        {/* <p>Chairman and founder</p> */}
      </div>
      <div style={{marginTop: 80}}>
        <p>
          {props.testContent}
        </p>
      </div>
    </Contain>
  );
}

export default AcaTestimonialContent;
