import axios from 'axios';
const url = window.location.search;
const getParam = new URLSearchParams(url);
const getToken = getParam.get('token')
const getName = getParam.get('name')
const firstLogin = getParam.get('firstLogin') 
if(getToken!=undefined){
    // sessionStorage.setItem("token",getToken); 
    // sessionStorage.setItem("name",getName); 
    // sessionStorage.setItem("first_page",firstLogin);
    localStorage.setItem("token",getToken); 
    localStorage.setItem("name",getName); 
    localStorage.setItem("first_page",firstLogin);
}
const token =  localStorage.getItem("token"); 
const name =  localStorage.getItem("name"); 

export default axios.create({
    
    baseURL:process.env.REACT_APP_BASE_URL,
    headers:{
        //'Authorization': 'Bearer '+token,
        'Access-Control-Allow-Origin':'*',
        'Content-Type': 'application/json',
    },
    
});