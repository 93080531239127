import React from 'react'
import  styled  from 'styled-components';
import WhatWeTab from './WhatWeTab';

function WhatWeDoMain(props) {
  const WhyUS = styled.div`
    display: flex;
    justify-content: center;
    align-item: center;
    padding: 15px;
    margin-bottom: 20px;
  `;
  const Head = styled.p`
    color: #000;
    margin: 0 !important;
    font-size: 25px;
    font-weight: 600;
  `;
  return (
    <div>
      <WhyUS>
        <Head>What We Do</Head>
      </WhyUS>
      <WhatWeTab 
        tab1={props.tabOne}
        tab2={props.tabTwo}
        tab3={props.tabThree}
        tab4={props.tabFour}
        picOne={props.picOne}
        picTwo={props.picTwo}
        picThree={props.picThree}
        picFour={props.picFour}
        tabheadone={props.tabheadone}
        tabonepoint1={props.tabonepoint1}
        tabonepoint2={props.tabonepoint2}
        tabonepoint3={props.tabonepoint3}
        tabheadtwo={props.tabheadtwo}
        tabsubtwo={props.tabsubtwo}
        tabtwopoint1={props.tabtwopoint1}
        tabtwopoint2={props.tabtwopoint2}
        tabtwopoint3={props.tabtwopoint3}
        tabtwopoint4={props.tabtwopoint4}

        tabheadthree={props.tabheadthree}
        tabsubthree={props.tabsubthree}
        tabthreepoint1={props.tabthreepoint1}
        tabthreepoint2={props.tabthreepoint2}
        tabthreepoint3={props.tabthreepoint3}
        tabthreepoint4={props.tabthreepoint4}

        tabheadfour={props.tabheadfour}
        tabsubfour={props.tabsubfour}
        tabfourpoint1={props.tabfourpoint1}
        tabfourpoint2={props.tabfourpoint2}
        tabfourpoint3={props.tabfourpoint3}
        tabfourpoint4={props.tabfourpoint4}
      />
      

    </div>
  )
}

export default WhatWeDoMain