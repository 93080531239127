import React from "react";
import styled from "styled-components";
import pic1 from "../../Assets/About_Page/middle_one.svg";

function Vision() {
  const Image = styled.img`
    height: 500px;

    @media only screen and (max-width: 768px) {
      height: 360px;
    }
  `;
  const ColOne = styled.div``;
  const Contain = styled.div`
    padding: 60px;

    @media only screen and (max-width: 768px) {
      padding: 35px;
    }
  `;
  const InnerCard = styled.div`
    background-color: #fae8ec;
    color: #000;
    padding: 20px;
    border-radius: 25px;
  `;
  const Text = styled.p`
    /* margin-top: 140px; */

    @media only screen and (max-width: 768px) {
      margin-top: 25px;
    }
  `;
  const Head = styled.div``;
  return (
    <Contain>
      <div className="row m-0">
        <ColOne className="col-md-6 mb-3 d-flex justify-content-end">
          <Image src={pic1} alt="our_vision" />
        </ColOne>
        <div className="col-md-6 mb-3 d-flex justify-content-center">
          <InnerCard className="">
            <Head className="d-flex text-center justify-content-center">
              <h4> Our Vision</h4>
            </Head>
            <Text>
              {/* <li> Upskill workforce so that the organization can continue ahead</li>
            <li>Deliver superior candidates for the organizations</li>
            <li>customized programs for end to end benefits of organizations</li> */}

              <li>
                We are India's largest platform for trained Freshers and Interns
                Workforce. We provide our students with live project
                opportunities in various industries, including the IT sector,
                banking, finance and insurance, healthcare, engineering and
                construction. Our mission is to be the best place for students
                to find work, gain practical experience in their chosen field by
                working on projects with companies before they get hired.
              </li>
                <br />
              <li>
                We believe that education should be affordable and accessible to
                everyone. We also believe that education should be relevant to
                students' interests and needs—not just what is required by
                employers. We want to enable every student who wants to learn
                more about something new or gain practical experience in a
                particular field of interest or skill set by working with
                companies on their projects before they get hired.
              </li>
              <br />

              <li>
                Our vision is to be earth's most student-centric company; to
                build a place where students can come to find and discover
                anything they want to learn and gain practical experience in by
                working with companies on their projects before they get hired.
              </li>
            </Text>
          </InnerCard>
        </div>
      </div>
    </Contain>
  );
}

export default Vision;
