import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";
import logo from "../../Assets/movidu Logo PNG_White.png";

const Navbar  = () => {
  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);
  const [isResponsiveclos, setResponsiveclos] = useState(false);
  const [isResponsiveclo, setResponsiveclo] = useState(false);
  const [fix, setFix] = useState(false);

  function setFixed() {
    if(window.scrollY >= 80){
      setFix(true)
    }else{
      setFix(false)
    }
  }

  window.addEventListener("scroll", setFixed)

  const toggleClass = () => {
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };
  const toggleClas = () => {
    setisMenu(isMenu === false ? true : false);
    setResponsiveclos(isResponsiveclos === false ? true : false);
  };
  const toggleCla = () => {
    setisMenu(isMenu === false ? true : false);
    setResponsiveclo(isResponsiveclo === false ? true : false);
  };

  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push("menuq2");
  } else {
    boxClass.push("");
  }

  const [isMenuSubMenu, setMenuSubMenu] = useState(false);
  const [isMenuSubMen, setMenuSubMen] = useState(false);
  const [isMenuSubMe, setMenuSubMe] = useState(false);

  // const toggleSubmenu = () => {
  //   setMenuSubMenu(isMenuSubMenu === false ? true : false);
  // };

  const toggleSubmen = () => {
    setMenuSubMen(isMenuSubMen === false ? true : false);
  };
  const toggleSubme = () => {
    setMenuSubMe(isMenuSubMe === false ? true : false);
  };

  let boxClassSubMenu = ["sub__menus"];
  if (isMenuSubMenu) {
    boxClassSubMenu.push("sub__menus__Active");
  } else {
    boxClassSubMenu.push("");
  }

  let boxClassSubMen = ["sub__menus"];
  if (isMenuSubMen) {
    boxClassSubMen.push("sub__menus__Active");
  } else {
    boxClassSubMen.push("");
  }

  let boxClassSubMe = ["sub__menus"];
  if (isMenuSubMe) {
    boxClassSubMe.push("sub__menus__Active");
  } else {
    boxClassSubMe.push("");
  }

  return (
    <header className="header__middle fixed">
      <div className="container-fluid ">
        <div className="row m-0">
          <div className="header__middle__logo">
            <NavLink exact activeClassName="is-active" to="/">
              <img src={logo} alt="logo" />
            </NavLink>
          </div>

          <div className="header__middle__menus">
            <nav className="main-nav ">
              {/* Responsive Menu Button */}
              {isResponsiveclose === true ? (
                <>
                  <span
                    className="menubar__button"
                    style={{ display: "none" }}
                    onClick={toggleClass}
                  >
                    <FiXCircle />
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="menubar__button"
                    style={{ display: "none" }}
                    onClick={toggleClass}
                  >
                    
                    <FiAlignRight />
                  </span>
                </>
              )}

              <ul className={boxClass.join(" ")}>
                <li className="menu-item">
                  <NavLink
                    exact
                    activeClassName="is-active"
                    onClick={toggleClass}
                    to={`/career`}
                  >
                    Career
                  </NavLink>
                </li>
                {/* <li className="menu-item">
                  <a 
                    activeClassName="is-active"
                    onClick={toggleClass}
                    href={process.env.REACT_APP_BACKEND_URL+'Projects'}
                  >
                    Career
                  </a>
                </li> */}
                <li className="menu-item">
                  <NavLink
                    exact
                    activeClassName="is-active"
                    onClick={toggleClass}
                    to={`/company`}
                  >
                    For Company
                  </NavLink>
                </li>
                <li
                  onClick={toggleSubmen}
                  className="menu-item sub__menus__arrows"
                >
                  <Link to=" ">
                    Our Partners <FiChevronDown />
                  </Link>
                  <ul className={boxClassSubMenu.join(" ")}>
                    {/* <li>
                      <NavLink
                        onClick={toggleClass}
                        activeClassName="is-active"
                        to={`/clg`}
                        className="dr"
                      >
                        College
                      </NavLink>
                    </li> */}
                    {/* <li>`
                      <NavLink
                        onClick={toggleClass}
                        activeClassName="is-active"
                        to={`/learningpartner`}
                        className="dr"
                      >
                        Academy
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink
                        onClick={toggleClass}
                        activeClassName="is-active"
                        to={`/mentor`}
                        className="dr"
                      >
                        Mentors
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        onClick={toggleClass}
                        activeClassName="is-active"
                        to={`/mentor`}
                        className="dr"
                      >
                        Sponsor
                      </NavLink>
                    </li> */}
                  </ul>
                </li>
                <li
                  onClick={toggleSubmen}
                  className="menu-item sub__menus__arrows"
                >
                  <Link to="#">
                    
                    About Us <FiChevronDown />
                  </Link>
                  <ul className={boxClassSubMen.join(" ")}>
                    <li>
                      
                      <NavLink
                        onClick={toggleClas}
                        activeClassName="is-active"
                        to={`/about`}
                        className="dr"
                      >
                        About Us
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        onClick={toggleClas}
                        activeClassName="is-active"
                        to={`/bloginfo`}
                        className="dr"
                      >
                        Blogs
                      </NavLink>
                    </li> */}
                    {/* <li>
                      <NavLink
                        onClick={toggleClas}
                        activeClassName="is-active"
                        to={`/learningpartner`}
                        className="dr"
                      >
                        Contact Us
                      </NavLink>
                    </li> */}
                  </ul>
                </li>
                <li
                  onClick={toggleSubme}
                  className="menu-item sub__menus__arrows"
                >
                  <Link to="/">
                    Login <FiChevronDown />
                  </Link>
                  <ul className={boxClassSubMe.join(" ")}>
                    <li>
                      
                      <NavLink
                        onClick={toggleCla}
                        activeClassName="is-active"
                        to={`/login`}
                        className="dr"
                      >
                        Company
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleCla}
                        activeClassName="is-active"
                        to={`/`}
                        className="dr"
                      >
                        Student
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        onClick={toggleCla}
                        activeClassName="is-active"
                        to={`/learningpartner`}
                        className="dr"
                      >
                        Our Partners
                      </NavLink>
                    </li> */}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
